import {
    ITextFieldStyles,
    IStackStyles,
    ICheckboxStyles,
    ISliderStyles,
    IDropdownStyles,
    IIconProps,
    IStyle,
    mergeStyleSets,
    IDocumentCardStyles,
    IDocumentCardPreviewStyles,
    IPivotStyles
} from "@fluentui/react";

import { createTheme } from "@fluentui/react";

export const textFieldStyles: Partial<ITextFieldStyles> = {
    wrapper: {
        selectors: {
            label: {
                fontFamily: "Urbanist"
            }
        }
    },
    root: {
        borderRadius: "5px"
    },
    fieldGroup: {
        width: "90vw",
        maxWidth: "800px",
        borderRadius: "5px",
        selectors: {
            "::after": {
                borderRadius: "5px ",
                border: "2px solid #404040"
            }
        }
    },
    subComponentStyles: {
        label: {
            root: {
                fontSize: "14px",
                fontFamily: "Urbanist"
            }
        }
    }
};
export const textFieldSiteMapStyles: Partial<ITextFieldStyles> = {
    wrapper: {
        selectors: {
            label: {
                fontFamily: "Urbanist"
            }
        }
    },
    root: {
        borderRadius: "5px"
    },
    fieldGroup: {
        width: "90vw",
        maxWidth: "300px",
        marginBottom: "10px",
        borderRadius: "5px",
        selectors: {
            "::after": {
                borderRadius: "5px ",
                border: "2px solid #404040"
            }
        }
    },
    subComponentStyles: {
        label: {
            root: {
                fontSize: "14px",
                fontFamily: "Urbanist"
            }
        }
    }
};
export const calloutStylesExportDocCallout = mergeStyleSets({
    container: {
        position: "relative",
        display: "inline-block",
        borderRadius: "7px"
    },
    triggerText: {
        cursor: "pointer"
    },
    callout: {
        backgroundColor: "#f8f8f8",
        border: "1px solid #ddd",

        borderRadius: "7px",

        boxShadow: "0 2px 4px rgba(0,0,0,0.2)"
    }
});
export const textFieldStylesDocExtractImportScheme: Partial<ITextFieldStyles> = {
    wrapper: {
        selectors: {
            label: {
                fontFamily: "Urbanist"
            }
        }
    },
    root: {
        borderRadius: "5px"
    },
    fieldGroup: {
        borderRadius: "5px",
        selectors: {
            "::after": {
                borderRadius: "5px ",
                border: "2px solid #404040"
            }
        }
    },
    subComponentStyles: {
        label: {
            root: {
                fontSize: "14px",
                fontFamily: "Urbanist"
            }
        }
    }
};
export const textFieldStylesPersonLabelRecognizer: Partial<ITextFieldStyles> = {
    wrapper: {
        selectors: {
            label: {
                fontFamily: "Urbanist"
            }
        }
    },
    root: {
        borderRadius: "5px"
    },
    fieldGroup: {
        borderRadius: "5px",
        maxWidth: 80,
        width: 80,
        marginRight: 8,
        selectors: {
            "::after": {
                borderRadius: "5px ",
                border: "2px solid #404040"
            }
        }
    },
    subComponentStyles: {
        label: {
            root: {
                fontSize: "14px",
                fontFamily: "Urbanist"
            }
        }
    }
};
export const titleTextFieldStylesPersonLabelRecognizer: Partial<ITextFieldStyles> = {
    wrapper: {
        selectors: {
            label: {
                fontFamily: "Roboto !important",
                fontSize: "27px !important"
            }
        }
    },
    root: {
        borderRadius: "5px",
        fontFamily: "Roboto !important",
        fontSize: "27px !important"
    },
    fieldGroup: {
        borderRadius: "5px",
        fontFamily: "Roboto !important",
        fontSize: "47px !important",
        marginRight: 8,
        selectors: {
            "::after": {
                borderRadius: "5px !important",
                border: "2px solid #404040"
            }
        }
    },
    subComponentStyles: {
        label: {
            root: {
                fontFamily: "Roboto !important",
                fontSize: "27px !important"
            }
        }
    }
};
export const textFieldStylesMultiline: Partial<ITextFieldStyles> = {
    wrapper: {
        selectors: {
            label: {
                fontFamily: "Urbanist"
            }
        }
    },
    root: {
        borderRadius: "8px"
    },
    fieldGroup: {
        width: "90vw",
        maxWidth: "800px",
        height: 100,
        borderRadius: "8px",
        selectors: {
            "::after": {
                borderRadius: "8px ",
                border: "2px solid #333"
            }
        }
    },
    subComponentStyles: {
        label: {
            root: {
                fontSize: "14px",
                fontFamily: "Urbanist"
            }
        }
    }
};
export const textFieldStylesMultilineRecognizer: Partial<ITextFieldStyles> = {
    wrapper: {
        width: "100%",
        maxWidth: "100%",
        marginLeft: "-5px",
        marginBottom: "-5px"
    },
    root: {
        borderRadius: "8px",
        width: "100%"
    },
    field: {
        font: "normal normal 13px/20px Urbanist,Roboto !important",
        margin: "0px",
        paddingTop: "9px",
        paddingLeft: "7px",
        paddingRight: "8px",
        width: "100%"
    },
    fieldGroup: {
        borderRadius: "8px",
        margin: "0",
        padding: "0",
        selectors: {
            "::after": {
                borderRadius: "8px ",
                border: "2px solid #333"
            }
        }
    }
};
export const checkBoxStyles: Partial<ICheckboxStyles> = {
    text: {
        fontFamily: "Urbanist"
    },
    checkbox: {
        background: "white",
        border: "1px solid #333",
        color: "white",
        borderColor: "#333", // Border color when unchecked
        selectors: {
            ":after": {
                content: '""',
                borderColor: "#333",
                background: "#333",
                color: "#333"
            },
            ":hover": {
                border: "3px solid #333"
            },
            ":focus": {
                border: "3px solid #333"
            },
            ":active": {
                border: "3px solid #333"
            }
        }
    },
    checkmark: {
        color: "white", // Color of the checkmark
        background: "#333",
        border: "3px solid #333",
        selectors: {
            ":hover": {
                color: "white", // Hover checkmark color
                border: "3px solid white"
            }
        }
    },
    root: {
        fontFamily: "Urbanist",
        selectors: {
            ":hover .ms-Checkbox-checkbox": {
                background: "#7e7e7e",
                border: "3px solid #7e7e7e"
            },
            ":hover .ms-Checkbox-checkmark": {
                background: "#7e7e7e", // Hover checkmark color
                border: "1px solid #7e7e7e"
            },
            ".is-checked .ms-Checkbox-checkbox": {
                background: "#7e7e7e",
                border: "3px solid #7e7e7e"
            }
        }
    }
};
export const stackStyles: Partial<IStackStyles> = {
    root: {
        gap: 10,
        border: "1px solid #9baebc",
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 0,
        paddingBottom: 20,
        marginBottom: "20px",
        borderRadius: "15px",
        width: "calc(100% + 20px)"
    }
};
export const tooltipStyles = {
    styles: {
        root: {
            borderRadius: "5px"
        },
        beak: {
            left: "20px !important",
            right: "auto !important"
        },
        beakCurtain: {
            borderRadius: "5px"
        },
        calloutMain: {
            borderRadius: "5px"
        }
    }
};
export const tooltipNoBeakStyles = {
    styles: {
        root: {
            borderRadius: "5px"
        },
        calloutMain: {
            borderRadius: "5px"
        },
        beakCurtain: {
            borderRadius: "5px"
        },
        beak: {
            zIndex: 0
        }
    }
};
export const sliderStyles: Partial<ISliderStyles> = {
    root: { marginTop: "10px" },
    titleLabel: {
        fontFamily: "Urbanist"
    },
    valueLabel: {
        fontFamily: "Urbanist"
    },
    slideBox: {
        borderRadius: "5px",
        color: "#333",
        borderColor: "#333",

        selectors: {
            ":hover": {
                color: "#333"
            },
            ":active": {
                color: "#333"
            },
            ":hover .ms-Slider-active": {
                background: "rgb(241, 100, 30) !important"
            },
            ":hover .ms-Slider-thumb": {
                borderColor: "orange !important",
                background: "white !important"
            }
        }
    },

    thumb: {
        selectors: {
            borderColor: "orange !important",
            backrgoundColor: "orange",
            color: "orange",
            ":hover": {
                borderColor: "orange !important",
                backrgoundColor: "orange",
                color: "orange"
            },
            ":active": {
                borderColor: "orange !important"
            }
        }
    },
    line: {
        selectors: {
            ":hover": {
                background: "orange",
                backrgoundColor: "orange",
                color: "orange"
            },

            ":active": {
                color: "#333",
                borderColor: "#333",
                background: "#333",
                backgroundColor: "#333"
            }
        }
    },
    lineContainer: {
        selectors: {
            ":hover": {
                selectors: {
                    ".ms-Slider-active": {
                        background: "orange",
                        backrgoundColor: "orange",
                        borderColor: "#333",
                        color: "orange"
                    },
                    ":active": {
                        color: "#333",
                        borderColor: "#333",
                        background: "#333",
                        backgroundColor: "#333"
                    }
                }
            }
        }
    }
};
export const dropdownStyles: Partial<IDropdownStyles> = {
    root: {
        padding: "0",
        margin: "0",
        borderRadius: "5px !important",
        color: "#333",
        borderColor: "#333",
        selectors: {
            ":focus": {
                borderRadius: "5px !important",
                borderColor: "#333 !important",
                color: "#333"
            },
            ":hover": {
                borderRadius: "5px !important",
                borderColor: "#333 !important",
                color: "#333"
            },
            ":active": {
                borderRadius: "5px !important",
                borderColor: "#333 !important",
                color: "#333"
            },
            ":after": {
                borderRadius: "5px !important",
                borderColor: "#333 !important",
                color: "#333"
            },
            ":before": {
                borderRadius: "5px !important",
                borderColor: "#333 !important",
                color: "#333"
            }
        }
    },
    label: {
        fontFamily: "Urbanist",
        fontSize: "15px",
        borderRadius: "5px !important",
        borderColor: "#333",
        width: "100%",
        marginBottom: "5px"
    },
    title: {
        fontFamily: "Urbanist",
        borderRadius: "5px !important",
        borderColor: "#333",
        fontSize: "12px",
        height: "30px",
        width: "100%"
    },

    dropdown: {
        borderRadius: "5px !important",
        color: "#333",
        borderColor: "#333",
        selectors: {
            ":focus": {
                borderRadius: "5px !important",
                borderColor: "#333 !important",
                color: "#333"
            },
            ":hover": {
                borderRadius: "5px !important",
                borderColor: "#333 !important",
                color: "#333"
            },
            ":active": {
                borderRadius: "5px !important",
                borderColor: "#333 !important",
                color: "#333"
            },
            ":after": {
                borderRadius: "5px !important",
                borderColor: "#333 !important",
                color: "#333"
            },
            ":before": {
                borderRadius: "5px !important",
                borderColor: "#333 !important",
                color: "#333"
            }
        }
    },
    dropdownItem: {
        fontFamily: "Urbanist",
        borderRadius: "5px !important",
        fontSize: "12px",
        height: "30px",
        color: "#333"
    },

    dropdownItemSelected: {
        fontFamily: "Urbanist",
        borderRadius: "5px !important",

        fontSize: "12px",
        height: "30px",
        color: "#333"
    },
    caretDown: {
        borderRadius: "5px !important",
        color: "#333"
    }
};
export const dropdownDetailsListStyles: Partial<IDropdownStyles> = {
    root: {
        marginTop: "-5px",
        marginBottom: "-5px",
        marginLeft: "-5px",
        borderRadius: "5px !important",
        color: "#333",
        borderColor: "#333",
        minWidth: "90px",
        selectors: {
            ":focus": {
                borderRadius: "5px !important",
                borderColor: "#333 !important",
                color: "#333"
            },
            ":hover": {
                borderRadius: "5px !important",
                borderColor: "#333 !important",
                color: "#333"
            },
            ":active": {
                borderRadius: "5px !important",
                borderColor: "#333 !important",
                color: "#333"
            },
            ":after": {
                borderRadius: "5px !important",
                borderColor: "#333 !important",
                color: "#333"
            },
            ":before": {
                borderRadius: "5px !important",
                borderColor: "#333 !important",
                color: "#333"
            }
        }
    },
    label: {
        borderRadius: "5px !important",
        borderColor: "#333",
        width: "100%"
    },
    title: {
        font: "normal normal 13px/30px Urbanist,Roboto",
        borderRadius: "5px !important",
        paddingLeft: "6px",
        marginTop: "-4px",
        marginBottom: "-4px",
        borderColor: "#333",
        fontSize: "12px",
        height: "30px",
        width: "100%"
    },

    dropdown: {
        borderRadius: "5px !important",
        color: "#333",
        borderColor: "#333",
        selectors: {
            ":focus": {
                borderRadius: "5px !important",
                borderColor: "#333 !important",
                color: "#333"
            },
            ":hover": {
                borderRadius: "5px !important",
                borderColor: "#333 !important",
                color: "#333"
            },
            ":active": {
                borderRadius: "5px !important",
                borderColor: "#333 !important",
                color: "#333"
            },
            ":after": {
                borderRadius: "5px !important",
                borderColor: "#333 !important",
                color: "#333"
            },
            ":before": {
                borderRadius: "5px !important",
                borderColor: "#333 !important",
                color: "#333"
            }
        }
    },
    dropdownItem: {
        fontFamily: "Urbanist",
        borderRadius: "5px !important",
        fontSize: "12px",
        height: "30px",
        color: "#333"
    },

    dropdownItemSelected: {
        fontFamily: "Urbanist",
        borderRadius: "5px !important",

        fontSize: "12px",
        height: "30px",
        color: "#333"
    },
    caretDown: {
        borderRadius: "5px !important",
        color: "#333"
    }
};
export const detailsListTheme = createTheme({
    palette: {
        themePrimary: "#00b977",
        themeLighterAlt: "#00b029"
    }
});
export const dropdownStylesSpaceUserListV2: Partial<IDropdownStyles> = {
    root: {
        padding: "0",
        margin: "0",
        borderRadius: "5px",
        selectors: {
            ":focus": {
                borderRadius: "5px"
            },
            ":hover": {
                borderRadius: "5px"
            },
            ":active": {
                borderRadius: "5px"
            }
        }
    },
    label: {
        fontFamily: "Urbanist",
        fontSize: "7px",
        borderRadius: "5px"
    },
    title: {
        fontFamily: "Urbanist",
        borderRadius: "5px",
        fontSize: "12px",
        height: "30px"
    },

    dropdown: {
        borderRadius: "5px",
        selectors: {
            ":focus": {
                borderRadius: "5px !important",
                borderWidth: "2px !important",
                borderColor: "#333 !important"
            },
            ":hover": {
                borderRadius: "5px !important",
                borderWidth: "2px !important",
                borderColor: "#333 !important"
            },
            ":active": {
                borderRadius: "5px !important",
                borderWidth: "2px !important",
                borderColor: "#333 !important"
            },
            ":after": {
                borderRadius: "5px !important",
                borderWidth: "2px !important",
                borderColor: "#333 !important"
            },
            ":before": {
                borderRadius: "5px !important",
                borderWidth: "2px !important",
                borderColor: "#333 !important"
            }
        }
    },
    dropdownItem: {
        fontFamily: "Urbanist",
        borderRadius: "5px",
        fontSize: "12px",
        height: "30px"
    },

    dropdownItemSelected: {
        fontFamily: "Urbanist",
        borderRadius: "5px",
        fontSize: "12px",
        height: "30px"
    },
    caretDown: {
        borderRadius: "5px"
    }
};
export const dropdownStylesInvUser: Partial<IDropdownStyles> = {
    root: {
        padding: "0",
        margin: "0",
        borderRadius: "5px",
        selectors: {
            ":focus": {
                borderRadius: "5px"
            },
            ":hover": {
                borderRadius: "5px"
            },
            ":active": {
                border: "none !important",
                outline: "none !important"
            }
        }
    },

    label: {
        fontFamily: "Urbanist",
        fontSize: "13px",
        borderRadius: "5px"
    },
    title: {
        fontFamily: "Urbanist",
        borderRadius: "5px",
        fontSize: "12px",
        height: "30px"
    },

    dropdown: {
        borderRadius: "5px",
        selectors: {
            ":focus": {
                borderRadius: "5px !important",
                borderWidth: "2px !important",
                borderColor: "#333 !important"
            },
            ":hover": {
                borderRadius: "5px !important",
                borderWidth: "2px !important",
                borderColor: "#333 !important"
            },
            ":active": {
                borderRadius: "5px !important",
                borderWidth: "2px !important",
                borderColor: "#333 !important"
            },
            ":after": {
                borderRadius: "5px !important",
                borderWidth: "2px !important",
                borderColor: "#333 !important"
            },
            ":before": {
                borderRadius: "5px !important",
                borderWidth: "2px !important",
                borderColor: "#333 !important"
            }
        }
    },
    dropdownItem: {
        fontFamily: "Urbanist",
        borderRadius: "5px",
        fontSize: "12px",
        height: "30px"
    },

    dropdownItemSelected: {
        fontFamily: "Urbanist",
        borderRadius: "5px",
        fontSize: "12px",
        height: "30px"
    },
    caretDown: {
        borderRadius: "5px"
    }
};
export const textFieldStylesSpaceUserInvListV2 = {
    root: {
        borderRadius: "5px"
    },
    fieldGroup: {
        borderRadius: "5px",
        selectors: {
            "::after": {
                borderRadius: "5px ",
                border: "2px solid #404040"
            }
        }
    },
    subComponentStyles: {
        label: {
            root: {
                fontSize: "14px",
                fontFamily: "Urbanist"
            }
        }
    }
};
export const controlStylesAnalyticsConvoList = {
    root: {
        paddingTop: "0px !important",
        width: "90vw",
        minWidth: "90vw",
        maxWidth: "100vw",
        overflowY: "scroll",
        height: "100%"
    },
    commandButton: {
        marginRight: "20px",
        marginBottom: "20px"
    },
    headerWrapper: {
        position: "sticky",
        top: 0,
        zIndex: 1,
        borderRadius: "5px 5px 0 0"
    } as IStyle,
    detailsHeader: { padding: "0px" }
};
export const textFieldStylesAnalyticsConvoList = {
    callout: { "& .ms-current-active-month": { fontWight: "bold", backgroundColor: "red" } },
    fieldGroup: {
        margin: "0px 15px 15px 0px",
        borderRadius: "5px",
        selectors: {
            ":after": {
                borderRadius: "5px"
            },
            ":hover": {
                borderRadius: "5px"
            }
        }
    },
    field: {
        borderRadius: "5px"
    }
};
export const assistantTypeDocCardStyles = {
    root: {
        transition: "box-shadow 0.1s ease-in-out, transform 0.03s ease-in-out",
        border: "none",
        selectors: {
            ":hover": {
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                border: "none"
            },
            ":hover:after": {
                border: "none"
            },
            ":active": {
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                border: "none"
            },
            ":active:after": {
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                border: "none"
            }
        }
    }
};
export const libPivotStyles = {
    root: {
        width: "100%",
        minWidth: "100%"
    },
    itemContainer: {
        width: "100%"
    },
    link: {
        selectors: {
            "&::before": {},
            "&:hover::before": {
                backgroundColor: "orange" // Change this to your desired hover color
            }
        }
    },
    linkIsSelected: {
        selectors: {
            "&::before": {
                backgroundColor: "orange" // Change this to your desired selected color
            }
        }
    }
};
export const addUrlCallout = {
    root: {
        borderRadius: "5px"
    },
    calloutMain: {
        borderRadius: "5px"
    },
    beakCurtain: { borderRadius: "5px" },
    beak: { borderRadius: "1px" }
};
export const addUrlYouTubeCallout = {
    root: {
        borderRadius: "5px"
    },
    calloutMain: {
        borderRadius: "5px"
    },
    beakCurtain: { borderRadius: "5px" },
    beak: { borderRadius: "1px" }
};
export const addUrlPrimaryButton = {
    rootHovered: {
        // Styles When Hovered and NOT Disabled
        backgroundColor: "#f1a41e",
        border: "1px solid rgba(241, 100, 30, 0)",
        selectors: {
            "&:not(.is-disabled)": {
                backgroundColor: "#f1a41e"
            }
        }
    }
};
export const siteMapPrimaryButton = {
    root: {
        minWidth: "0px !important"
    },
    rootHovered: {
        // Styles When Hovered and NOT Disabled
        backgroundColor: "#f1a41e",
        border: "1px solid rgba(241, 100, 30, 0)",
        selectors: {
            "&:not(.is-disabled)": {
                backgroundColor: "#f1a41e"
            }
        }
    }
};
export const newSpaceDocCardStyles = {
    root: {
        width: 300,
        paddingBottom: "20px",

        marginBottom: -10,
        marginTop: 30,
        borderRadius: 10,
        textAlign: "center",
        border: "none",
        backgroundColor: "rgba(193, 200, 214,0.16)",
        transition: "box-shadow 0.1s ease-in-out, transform 0.03s ease-in-out",
        selectors: {
            ":hover": {
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                border: "none"
            },
            ":hover:after": {
                border: "none"
            },
            ":active": {
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                transform: "translateY(4px)",
                border: "none",
                borderRadius: 10
            },
            ":active:after": {
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                transform: "translateY(2px)",
                border: "none",
                borderRadius: 10
            }
        }
    }
};
export const newSpaceDocTitleCardStyles = {
    root: {
        font: "normal normal 1000 18px/22px Urbanist, Roboto",
        color: "#485a73",
        textAlign: "center",
        marginBottom: -8,
        height: "auto"
    }
};
export const uploadCardStyles = {
    root: {
        marginBottom: 0,
        marginTop: 20,
        borderRadius: 10,
        padding: "10px 20px 10px 20px",
        position: "relative" as "relative",
        opacity: 0.9,
        selectors: {
            ":hover": {
                transform: "translateY(-3px)",
                transition: "transform 0.2s ease-in-out"
            },
            ":active": {
                transform: "translateY(-3px) scale(0.99)",
                transition: "transform 0.01s ease-in-out"
            },
            ":hover:after": {
                border: "none"
            }
        }
    }
};
export const disabledCardStyles = {
    root: {
        marginBottom: 0,
        marginTop: 20,
        borderRadius: 10,
        padding: "10px 20px 10px 20px",
        position: "relative" as "relative",
        opacity: 0.9
    }
};
export const textFieldInvoiceFieldChooser: Partial<ITextFieldStyles> = {
    wrapper: {
        selectors: {
            label: {
                fontFamily: "Urbanist, Roboto"
            }
        }
    },
    field: { lineHeight: "18px", padding: "2px 4px", fontFamily: "Urbanist, Roboto" },
    root: {
        borderRadius: "8px",
        border: "1px solid #cedae5"
    },
    fieldGroup: {
        height: "40px",
        minHeight: "25px",
        maxHeight: "40px",
        borderRadius: "8px",
        border: "1px solid #cedae5",
        selectors: {
            border: "1px solid #cedae5",
            "::after": {
                borderRadius: "8px ",
                border: "1px solid #4bbc8e"
            },
            "::hover": {
                borderRadius: "8px ",
                border: "1px solid #4bbc8e"
            },
            "::active": {
                borderRadius: "8px ",
                border: "1px solid #4bbc8e"
            },
            "::focus": {
                borderRadius: "8px ",
                border: "1px solid #4bbc8e"
            }
        }
    },
    subComponentStyles: {
        label: {
            root: {
                fontSize: "14px",
                fontFamily: "Urbanist"
            }
        }
    }
};
export const clipIcon: IIconProps = {
    iconName: "Attach",
    styles: {
        root: {
            color: "#333333",
            transform: "rotate(-20deg)",
            fontSize: "20px"
        }
    }
};
export const loadIcon: IIconProps = {
    iconName: "Sync",
    styles: {
        root: {
            color: "#333333",
            transform: "rotate(-20deg)",
            fontSize: "20px"
        }
    }
};
export const actionButtonStyles = {
    iconHovered: { color: "#333333" }
};
export const questionInputUploadCallout = {
    root: {
        height: "auto",
        borderRadius: "5px",
        backgroundColor: "rgba(255, 255, 255, 0)"
    },
    calloutMain: {
        borderRadius: "5px"
    },
    beakCurtain: { borderRadius: "5px" },
    beak: { borderRadius: "1px" }
};
export const questionInputTextArea = {
    root: {
        verticalAlign: "middle",
        height: "0px"
    },
    fieldGroup: { minHeight: "0px", height: "0px" },
    field: {
        paddingTop: "11px",
        paddingBottom: "11px",
        height: "0px",
        minHeight: "0",
        selectors: {
            ":disabled": {
                backgroundColor: "#ffaf87",
                color: "red",
                font: "normal normal 17px/20px Urbanist,Roboto"
            }
        },
        font: "normal normal 15px/20px Urbanist,Roboto;"
    }
};
export const textFieldStylesDocExtr: Partial<ITextFieldStyles> = {
    wrapper: {
        selectors: {
            label: {
                fontFamily: "Urbanist"
            }
        }
    },
    root: {
        margin: "-5px",
        borderRadius: "5px"
    },
    fieldGroup: {
        borderRadius: "5px",
        minWidth: "110px",
        selectors: {
            "::after": {
                borderRadius: "5px ",
                border: "2px solid #404040"
            }
        }
    },
    subComponentStyles: {
        label: {
            root: {
                fontSize: "14px",
                fontFamily: "Urbanist"
            }
        }
    }
};
export const dropdownStylesDocExtr: Partial<IDropdownStyles> = {
    root: {
        padding: "0",
        margin: "-5px",
        borderRadius: "5px !important",
        borderColor: "#333",
        selectors: {
            ":focus": {
                borderRadius: "5px !important",
                borderColor: "#333 !important"
            },
            ":hover": {
                borderRadius: "5px !important",
                borderColor: "#333 !important"
            },
            ":active": {
                borderRadius: "5px !important",
                borderColor: "#333 !important"
            },
            ":after": {
                borderRadius: "5px !important",
                borderColor: "#333 !important"
            },
            ":before": {
                borderRadius: "5px !important",
                borderColor: "#333 !important"
            }
        }
    },
    label: {
        fontFamily: "Urbanist",
        fontSize: "14px",
        borderRadius: "5px !important",
        width: "100%"
    },
    title: {
        fontFamily: "Urbanist",
        borderRadius: "5px !important",
        borderColor: "#333",
        fontSize: "14px",
        height: "32px",
        width: "100%",
        minWidth: "80px"
    },
    dropdown: {
        borderRadius: "5px !important",
        borderColor: "#333",
        selectors: {
            ":focus": {
                borderRadius: "5px !important",
                borderColor: "#333 !important"
            },
            ":hover": {
                borderRadius: "5px !important",
                borderColor: "#333 !important"
            },
            ":active": {
                borderRadius: "5px !important",
                borderColor: "#333 !important"
            },
            ":after": {
                borderRadius: "5px !important",
                borderColor: "#333 !important"
            },
            ":before": {
                borderRadius: "5px !important",
                borderColor: "#333 !important"
            }
        }
    },
    dropdownItem: {
        fontFamily: "Urbanist",
        borderRadius: "5px !important",
        fontSize: "14px",
        height: "30px"
    },
    dropdownItemSelected: {
        fontFamily: "Urbanist",
        borderRadius: "5px !important",

        fontSize: "14px",
        height: "30px"
    },
    caretDown: {
        borderRadius: "5px !important"
    }
};
export const textFieldStylesMultilineDocExtr: Partial<ITextFieldStyles> = {
    wrapper: {
        selectors: {
            label: {
                fontFamily: "Urbanist"
            }
        }
    },
    root: {
        marginTop: "-5px",
        borderRadius: "5px"
    },
    fieldGroup: {
        height: 32,
        minHeight: 32,

        width: "calc(100% - 20px)",
        borderRadius: "5px",
        selectors: {
            "::after": {
                borderRadius: "5px ",
                border: "2px solid #333"
            }
        }
    },
    subComponentStyles: {
        label: {
            root: {
                fontSize: "14px",
                fontFamily: "Urbanist"
            }
        }
    }
};
export const textFieldStylesMultilineSiteMap: Partial<ITextFieldStyles> = {
    wrapper: {
        selectors: {
            label: {
                fontFamily: "Urbanist"
            }
        }
    },
    root: {
        marginTop: "-5px",
        borderRadius: "8px",
        marginBotom: "5px"
    },
    fieldGroup: {
        height: 72,
        width: "100%",
        minWidth: "300px",
        maxWidth: "500px",
        borderRadius: "8px",
        marginBotom: "5px",
        selectors: {
            "::after": {
                borderRadius: "8px ",
                border: "2px solid #333"
            }
        }
    },
    subComponentStyles: {
        label: {
            root: {
                fontSize: "14px",
                fontFamily: "Urbanist"
            }
        }
    }
};
export const assitantCardsDropdownStyles: Partial<IDropdownStyles> = {
    root: {
        padding: "0",
        margin: "0 5px 0 0",

        borderRadius: "5px 5px 0 0 !important",
        color: "white",
        borderColor: "grey",
        width: "115px",
        selectors: {
            ":focus": {
                borderRadius: "5px 5px 0 0 !important",
                borderColor: "grey !important",
                color: "white"
            },
            ":hover": {
                borderRadius: "5px 5px 0 0 !important",
                borderColor: "grey !important",
                color: "white !important"
            },
            ":active": {
                borderRadius: "5px 5px 0 0 !important",
                borderColor: "grey !important",
                color: "white"
            },
            ":after": {
                borderRadius: "5px 5px 0 0 !important",
                borderColor: "grey !important",
                color: "white"
            },
            ":before": {
                borderRadius: "5px 5px 0 0 !important",
                borderColor: "grey !important",
                color: "white"
            }
        }
    },
    label: {
        fontFamily: "Urbanist",
        fontSize: "12px",

        borderRadius: "5px !important",
        borderColor: "grey",
        width: "100%",
        color: "white !important",
        marginBottom: "5px"
    },
    title: {
        fontFamily: "Urbanist",
        borderRadius: "5px 5px 0 0 !important",
        borderColor: "grey",
        fontSize: "12px",
        height: "30px",
        width: "100%",
        background: "grey",
        color: "white !important",
        selectors: {
            ":focus": {
                borderRadius: "5px 5px 0 0 !important",
                borderColor: "grey !important",
                color: "white"
            },
            ":hover": {
                borderRadius: "5px 5px 0 0 !important",
                borderColor: "grey !important",
                color: "white !important"
            },
            ":active": {
                borderRadius: "5px 5px 0 0 !important",
                borderColor: "grey !important",
                color: "white"
            },
            ":after": {
                borderRadius: "5px 5px 0 0 !important",
                borderColor: "grey !important",
                color: "white"
            },
            ":before": {
                borderRadius: "5px 5px 0 0 !important",
                borderColor: "grey !important",
                color: "#333"
            }
        }
    },
    dropdown: {
        borderRadius: "5px 5px 0 0 !important",
        color: "white",
        borderColor: "grey",

        selectors: {
            ":focus": {
                borderRadius: "5px 5px 0 0 !important",
                borderColor: "grey !important",
                color: "white"
            },
            ":hover": {
                borderRadius: "5px 5px 0 0 !important",
                borderColor: "grey !important",
                color: "white !important"
            },
            ":active": {
                borderRadius: "5px 5px 0 0 !important",
                borderColor: "grey !important",
                color: "white"
            },
            ":after": {
                borderRadius: "5px !important",
                borderColor: "grey !important",
                color: "white"
            },
            ":before": {
                borderRadius: "5px 5px 0 0 !important",
                borderColor: "grey !important",
                color: "#333"
            }
        }
    },
    dropdownItem: {
        fontFamily: "Urbanist",
        fontSize: "12px",
        height: "30px",
        color: "white",
        backgroundColor: "grey"
    },
    dropdownItemSelected: {
        fontFamily: "Urbanist",

        fontSize: "12px",
        height: "30px",
        color: "white",
        backgroundColor: "grey"
    },
    dropdownItemHeader: {
        fontFamily: "Urbanist",
        borderRadius: "15px !important",
        fontSize: "12px",
        height: "30px",
        color: "white !important"
    },
    caretDown: {
        borderRadius: "15px !important",
        color: "#333"
    }
};
export const docGreetDocCardStyle: IDocumentCardStyles = {
    root: {}
};
export const prdAssistantExternalFileListStyle: Partial<IDocumentCardPreviewStyles> = {
    root: {
        margin: "0px",
        overflow: "hidden",
        borderRadius: "5px",
        padding: "0px",
        overflowY: "hidden"
    }
};
export const chatAdmPivotStyles: Partial<IPivotStyles> = {
    root: {
        width: "100%",
        minWidth: "100%"
    },
    itemContainer: {
        width: "100%"
    }
};
