import React, { useState, useCallback, useRef, useEffect } from "react";
import { TooltipHost, TooltipDelay, Spinner, SpinnerSize } from "@fluentui/react";
import * as sdk from "microsoft-cognitiveservices-speech-sdk";
import styles from "./../QuestionInput/QuestionInput.module.css";
import { IoMicOutline, IoMicOffOutline } from "react-icons/io5";
import { prepRecog, createVisGradient } from "./../../api/aud";
import { gselectedgroup, gspeakprops } from "../../interfaces";

interface SpeechRecognizerProps {
    onRecognized: (text: string, detectedLanguage: string) => void;
    onRecognizing: (text: string, detectedLanguage: string) => void;
    isOnCallback: (isOn: boolean) => void;
    selectedGroup: gselectedgroup;
    isSpeaking?: boolean;
    isSpeechPaused: boolean | null;
}

const SpeechRecognizer: React.FC<SpeechRecognizerProps> = ({ onRecognized, onRecognizing, isOnCallback, selectedGroup, isSpeaking, isSpeechPaused }) => {
    const [isListening, setIsListening] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [convoLang, setConvoLang] = useState<string>(selectedGroup.convolang);
    const [isOn, setIsOn] = useState<boolean>(false);
    const recRef = useRef<sdk.SpeechRecognizer | null>(null);
    const visAudCtxRef = useRef<AudioContext | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);

    useEffect(() => {
        return () => {
            try {
                if (recRef.current) recRef.current.close();
            } catch {}
        };
    }, []);

    useEffect(() => {
        if (isListening) initVisualizer();
    }, [isListening]);

    useEffect(() => {
        if (isSpeaking == false && isOn == true) {
            startListening();
        }
    }, [isSpeaking]);

    useEffect(() => {
        if (isSpeechPaused == true && isSpeaking == true && isOn == true) {
            startListening();
        } else if (isSpeechPaused == false) {
            stopListening();
        }
    }, [isSpeechPaused]);

    const initRec = useCallback((): sdk.SpeechRecognizer => {
        const newRecognizer = prepRecog(convoLang);
        newRecognizer.recognized = recRecognized;
        newRecognizer.recognizing = recRecognizing;
        newRecognizer.canceled = recCanceled;
        newRecognizer.sessionStarted = recStarted;
        newRecognizer.sessionStopped = recStopped;
        return newRecognizer;
    }, [onRecognized, onRecognizing]);

    const startListening = useCallback((): void => {
        if (!recRef.current) recRef.current = initRec();
        recRef.current.startContinuousRecognitionAsync(
            () => {
                setIsListening(true);
                setIsLoading(false);
            },
            error => console.error("errr_start_rec:", error)
        );
    }, [initRec]);

    const stopListening = useCallback((): void => {
        if (recRef.current) {
            recRef.current.stopContinuousRecognitionAsync(
                () => {
                    postStop();
                },
                error => console.error("errr_stop_rec", error)
            );
        }
    }, []);

    const toggleListening = useCallback((): void => {
        if (isListening) {
            setIsLoading(true);
            setIsOn(false);
            isOnCallback(false);
            stopListening();
        } else {
            setIsLoading(true);
            setIsOn(true);
            isOnCallback(true);
            startListening();
        }
    }, [isListening, startListening, stopListening]);

    const recRecognized = (s: sdk.Recognizer, e: sdk.SpeechRecognitionEventArgs) => {
        if (e.result.reason == sdk.ResultReason.RecognizedSpeech) {
            try {
                import.meta.env.DEV === true && console.log("rcgnzd", e.result.text, e.result.language);
                onRecognized(e.result.text, e.result.language); // sdk.AutoDetectSourceLanguageResult.fromResult(e.result).language
            } catch (error) {
                console.error("errr_rcgnzd", error);
            }
            //restartRec();
            stopListening();
        }
    };

    const recRecognizing = (s: sdk.Recognizer, e: sdk.SpeechRecognitionEventArgs) => {
        try {
            import.meta.env.DEV === true && console.log("rcgnzng", e.result.text, e.result.language);
            onRecognizing(e.result.text, e.result.language);
        } catch (error) {
            console.error("errr_rcgzng", error);
        }
    };
    const recStarted = (s: sdk.Recognizer, e: sdk.SessionEventArgs) => {
        import.meta.env.DEV === true && console.log("stt_start");
    };
    const recCanceled = (s: sdk.Recognizer, e: sdk.SpeechRecognitionCanceledEventArgs) => {
        import.meta.env.DEV === true && console.log("stt_cancel");
    };
    const recStopped = (s: sdk.Recognizer, e: sdk.SessionEventArgs) => {
        import.meta.env.DEV === true && console.log("stt_stop", e);
    };

    const postStop = (): void => {
        setIsLoading(false);
        setIsListening(false);
        if (recRef.current) {
            try {
                recRef.current.close();
                recRef.current = null;
            } catch {}
        }
        if (visAudCtxRef.current) {
            try {
                visAudCtxRef.current.close();
                visAudCtxRef.current = null;
            } catch {}
        }
    };

    const initVisualizer = async () => {
        try {
            navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
                visAudCtxRef.current = new AudioContext();
                const sourceNode = visAudCtxRef.current.createMediaStreamSource(stream);
                const analyserNode = visAudCtxRef.current.createAnalyser();
                analyserNode.fftSize = 64;
                sourceNode.connect(analyserNode);
                const canvas = canvasRef.current;
                if (canvas) {
                    const canvasCtx = canvas.getContext("2d");
                    const bufferLength = analyserNode.frequencyBinCount;
                    const dataArray = new Uint8Array(bufferLength);
                    let barHeights = new Array(bufferLength).fill(0);
                    const drawEqualizer = () => {
                        analyserNode.getByteFrequencyData(dataArray);
                        if (!canvasCtx) return;
                        canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
                        dataArray.slice(0, 10).forEach((value, i) => {
                            const targetHeight = (value / 255) * canvas.height;
                            barHeights[i] = barHeights[i] + (targetHeight - barHeights[i]) * 0.3; // Easing

                            const barWidth = canvas.width / 10;
                            canvasCtx.fillStyle = createVisGradient(canvasCtx, canvas, barHeights[i]);
                            canvasCtx.fillRect(i * barWidth, canvas.height - barHeights[i], barWidth, barHeights[i]);
                        });
                        if (isListening == true) {
                            requestAnimationFrame(drawEqualizer);
                        } else {
                            canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
                            stream.getTracks().forEach(track => track.stop());
                        }
                    };
                    drawEqualizer();
                }
            });
        } catch (error) {
            console.error("error_init_vis", error);
        }
    };

    return (
        <div className={styles.spchRecContainer}>
            <TooltipHost content="Conversational Mode" id="myTooltipId" calloutProps={{ gapSpace: 0 }} delay={TooltipDelay.zero}>
                <div onClick={toggleListening} className={styles.spchInpIconToggle}>
                    {isOn ? (
                        <IoMicOffOutline size={25} />
                    ) : isLoading == false ? (
                        <IoMicOutline size={25} />
                    ) : (
                        <Spinner size={SpinnerSize.small} className={styles.recLoadingSpinner} />
                    )}
                </div>
            </TooltipHost>
            {(isListening || isSpeaking) && <canvas ref={canvasRef} className={styles.recEqCanvas} />}
        </div>
    );
};

export default SpeechRecognizer;
