import React, { useState, useMemo } from "react";
import "./JsonGrid.css";
import { TbEditCircle } from "react-icons/tb";
import { MdDelete } from "react-icons/md";
import { qUpdateInvoiceStructuredData, qAddInvInstrct, qDelInvInstrct, qGetInvInstrct } from "../../api/qpi";
import { gloggedinuser } from "../../interfaces";
import { toast } from "react-toastify";
import { Callout } from "@fluentui/react";
import { IoAddCircleOutline } from "react-icons/io5";
import { BiCustomize } from "react-icons/bi";
import { FaChevronCircleUp } from "react-icons/fa";
import { FaChevronCircleDown } from "react-icons/fa";
import { BsChevronExpand } from "react-icons/bs";
import { gcustominvoiceinstructionscallout, gselectedgroup, gcustominvoiceinstructions } from "../../interfaces";
import { finFieldList } from "../../lsts";

import InstructTagInput from "../InstructTagInput/InstructTagInput";

interface JsonGridProps {
    jsonString?: string;
    itmName?: string;
    fid?: string;
    loggedInUser?: gloggedinuser;
    onDataHighlight?: (searchString: string) => void;
    onInvValueUpdate?: () => void;
    group?: gselectedgroup;
}
import { TooltipHost, DirectionalHint } from "@fluentui/react";
import { set } from "lodash";
const non_editables = [
    "grand_total_mismatch",
    "items_with_mismatches",
    "items_with_insufficient_data",
    "insufficient_totals_data",
    "calculated_invoice_items_total",
    "total_mismatch",
    "calculated_total_price",
    "insufficient_pricing_data"
];
const togglebles = ["item_measure", "unit_price", "item_type", "item_code", "quantity"];

const hidden_fields = [
    "items_with_mismatches",
    "items_with_insufficient_data",
    "insufficient_pricing_data",
    "total_mismatch",
    "insufficient_totals_data",
    "premain_data",
    "multiple_invoice_suspicion",
    "duplicate_invoice_suspicion",
    "notices",
    "status_message",
    "extraction_success_code"
];
const itmOrd = [
    "invoice_id",
    "grand_total_mismatch",
    "items_with_mismatches",
    "items_with_insufficient_data",
    "insufficient_totals_data",
    "calculated_invoice_items_total",
    "invoice_total",
    "invoice_date",
    "contractor_name",
    "contractor_address",
    "contractor_vat_identification",
    "iban",
    "customer_name",
    "customer_address",
    "customer_vat_identification",
    "invoice_total",
    "vat",
    "invoice_currency",
    "invoice_items",
    "item_number",
    "item_code",
    "item_type",
    "description",
    "item_code",
    "quantity",
    "item_measure",
    "unit_price",
    "calculated_total_price",
    "total_price",
    "item_code"
];
const JsonGrid: React.FC<JsonGridProps> = ({ jsonString, itmName, fid, loggedInUser, onDataHighlight, onInvValueUpdate, group }) => {
    const [gridData, setGridData] = useState(jsonString ? JSON.parse(jsonString) : "{}");
    const [editingPaths, setEditingPaths] = useState<{ [path: string]: boolean }>({});
    const [editingValues, setEditingValues] = useState<{ [path: string]: string }>({});
    const [isExpanded, setIsExpanded] = useState(true);
    const [instructCalloutTargetElement, setInstructCalloutTargetElement] = useState<HTMLDivElement | null>(null);
    const [isOverviewTableExpanded, setIsOverviewTableExpanded] = useState(false);
    const [isInstructionCalloutOpen, setIsInstructionCalloutOpen] = useState<gcustominvoiceinstructionscallout>();

    const prePreJson = (group: gselectedgroup | undefined) => {
        try {
            let aa = JSON.parse(group?.extractionfieldlist || "{excl:[],instr:[]}");
            if (aa?.excl && aa?.excl.length > 0) {
                return aa;
            } else {
                return [];
            }
        } catch (e) {
            return [];
        }
    };

    const [fieldsTurnedOff, setFieldsTurnedOff] = useState<string[]>([...hidden_fields, ...prePreJson(group)]);
    const fieldsToShow = useMemo(() => {
        try {
            return finFieldList.filter(field => !(JSON.parse(group?.extractionfieldlist || "{excl:[],instr:[]}")?.excl || ([] as string[])).includes(field.id));
        } catch (e) {
            return [];
        }
    }, [group]);
    const itemFields = useMemo(() => fieldsToShow.filter(field => field.level === "item").map(field => field.id), [group]);

    const sortObjectByKeyOrder = (obj: any, keyOrder: string[]) => {
        return Object.fromEntries(
            Object.entries(obj).sort(([a], [b]) => {
                return keyOrder.indexOf(a) - keyOrder.indexOf(b);
            })
        );
    };
    const pathToString = (path: (string | number)[]): string => {
        return path.map(p => String(p)).join(">");
    };
    const startEditing = (pathAsString: string, initialValue: string) => {
        setEditingPaths(prev => ({
            ...prev,
            [pathAsString]: true
        }));
        setEditingValues(prev => ({
            ...prev,
            [pathAsString]: initialValue
        }));
    };
    const openCallout = (pathAsString: string) => {
        const lastPath = pathAsString.split(">").pop();
        if (lastPath) {
            getInstructions(lastPath);
        }
    };
    const cancelEditing = (pathAsString: string) => {
        setEditingPaths(prev => {
            const updated = { ...prev };
            delete updated[pathAsString];
            return updated;
        });
        setEditingValues(prev => {
            const updated = { ...prev };
            delete updated[pathAsString];
            return updated;
        });
    };
    const saveEditing = (pathAsString: string, path: (string | number)[]) => {
        const newValue = editingValues[pathAsString];
        const updatedData = setValueAtPath(gridData, path, newValue);
        setGridData(updatedData);
        if (loggedInUser) {
            qUpdateInvoiceStructuredData(loggedInUser, updatedData, fid || "", group?.selectionId || "").then(res => {
                if (res.status == "success") {
                    toast.success("Invoice data updated successfully");
                } else {
                    toast.error("Error updating invoice data");
                }
            });
        }

        if (onInvValueUpdate) {
            onInvValueUpdate();
        }
        cancelEditing(pathAsString);
    };
    const setValueAtPath = (obj: any, path: (string | number)[], value: any): any => {
        if (path.length === 0) {
            return value;
        }
        const [firstKey, ...restPath] = path;

        let updatedValue;
        if (restPath.length === 0) {
            updatedValue = value;
        } else {
            const currentObj = obj && obj[firstKey] !== undefined ? obj[firstKey] : typeof restPath[0] === "number" ? [] : {};
            updatedValue = setValueAtPath(currentObj, restPath, value);
        }

        if (Array.isArray(obj)) {
            const newArray = [...obj];
            newArray[firstKey as number] = updatedValue;
            return newArray;
        } else {
            return {
                ...obj,
                [firstKey]: updatedValue
            };
        }
    };
    const getValueAtPath = (obj: any, path: (string | number)[]) => {
        return path.reduce((acc, key) => {
            if (acc === undefined || acc === null) {
                return undefined;
            } else {
                return acc[key];
            }
        }, obj);
    };
    const handleAddItem = (path: (string | number)[]) => {
        const arrayAtPath = getValueAtPath(gridData, path);
        if (Array.isArray(arrayAtPath)) {
            const newItem = Object.fromEntries(itemFields.map(key => [key, ""]));
            const updatedArray = [...arrayAtPath, newItem];
            const updatedData = setValueAtPath(gridData, path, updatedArray);
            setGridData(updatedData);

            if (onInvValueUpdate) {
                onInvValueUpdate();
            }
        } else {
            console.error("Value at path is not an array");
        }
    };
    const deleteValueAtPath = (obj: any, path: (string | number)[]): any => {
        if (path.length === 0) {
            return obj;
        }
        const [firstKey, ...restPath] = path;
        if (restPath.length === 0) {
            if (Array.isArray(obj)) {
                const newArray = [...obj];
                newArray.splice(firstKey as number, 1);
                return newArray;
            } else {
                const { [firstKey]: deletedKey, ...rest } = obj;
                return rest;
            }
        } else {
            // Recurse into the object or array
            if (Array.isArray(obj)) {
                const newArray = [...obj];
                newArray[firstKey as number] = deleteValueAtPath(obj[firstKey as number], restPath);
                return newArray;
            } else {
                return {
                    ...obj,
                    [firstKey]: deleteValueAtPath(obj[firstKey], restPath)
                };
            }
        }
    };
    const handleDelete = (pathAsString: string, path: (string | number)[]) => {
        const updatedData = deleteValueAtPath(gridData, path);
        setGridData(updatedData);
        if (loggedInUser) {
            qUpdateInvoiceStructuredData(loggedInUser, updatedData, fid || "", group?.selectionId || "").then(res => {
                if (res.status == "success") {
                    toast.success("Invoice data updated successfully");
                } else {
                    toast.error("Error updating invoice data");
                }
            });
        }
        if (onInvValueUpdate) {
            onInvValueUpdate();
        }
    };
    const handleInputChange = (pathAsString: string, newValue: string) => {
        setEditingValues(prev => ({
            ...prev,
            [pathAsString]: newValue
        }));
    };
    const toggleItems = (arrayItemPathAsString: string, fieldsArray: string[]) => {
        fieldsArray.forEach(field => {
            const element = document.getElementById(`${arrayItemPathAsString}-${field}`);
            if (element) {
                element.style.display = element.style.display === "none" ? "" : "none";
            }
        });
    };
    const toggleOverviewTable = () => {
        setIsOverviewTableExpanded(!isOverviewTableExpanded);
    };
    const toggleAllRows = () => {
        const elements = Array.from(document.querySelectorAll(".level-1_z_tglb")) as HTMLDivElement[];
        if (isExpanded === false) {
            elements.forEach((el: HTMLDivElement) => {
                el.style.display = "none";
            });
        } else {
            elements.forEach((el: HTMLDivElement) => {
                el.style.display = "";
            });
        }
        elements.forEach((el: HTMLDivElement) => {
            el.style.display = el.style.display === "none" ? "" : "none";
        });

        setIsExpanded(!isExpanded);
    };
    const getInstructions = (fieldname: string) => {
        if (loggedInUser) {
            qGetInvInstrct(loggedInUser, group?.selectionId || "", gridData["contractor_vat_identification"], fieldname).then(res => {
                if (res) {
                    setIsInstructionCalloutOpen({
                        isOpen: true,
                        path: fieldname,
                        instructions: res
                    });
                } else {
                    toast.error("Error getting custom instruction");
                }
            });
        }
    };
    const handleInstructionOp = (tag: gcustominvoiceinstructions, isAdd: boolean) => {
        if (loggedInUser) {
            if (isAdd) {
                saveInstruction(tag);
            } else {
                removeInstruction(tag);
            }
        }
    };
    const saveInstruction = (tag: gcustominvoiceinstructions) => {
        if (loggedInUser) {
            qAddInvInstrct(loggedInUser, tag).then(res => {
                if (res.status == "success") {
                    toast.success("Custom Instruction Added Successfully");
                } else {
                    toast.error("Error adding custom instruction");
                }
            });
        }
    };
    const removeInstruction = (tag: gcustominvoiceinstructions) => {
        if (loggedInUser) {
            qDelInvInstrct(loggedInUser, tag.id).then(res => {
                if (res.status == "success") {
                    toast.success("Custom Instruction Removed Successfully");
                } else {
                    toast.error("Error removing custom instruction");
                }
            });
        }
    };
    const renderGrid = (data: any, path: (string | number)[] = [], level = 0) => {
        return Object.entries(sortObjectByKeyOrder(data, itmOrd))
            .filter(([key, value]) => !(key === "page" && level === 0))
            .sort(([key1, value1], [key2, value2]) => {
                if (key1 == "page") {
                    return itmOrd.indexOf(key1) - itmOrd.indexOf(key2);
                }
                if (key1 == "item_number") {
                    return itmOrd.indexOf(key1) - itmOrd.indexOf(key1);
                }
                return 0;
            })
            .map(([key, value]) => {
                const currentPath = [...path, key];
                const pathAsString = pathToString(currentPath);
                return (
                    <div
                        key={pathAsString}
                        id={pathAsString?.replace(/>/g, "-")}
                        className={`${
                            fieldsTurnedOff.includes(key)
                                ? "grid-row_z_hidden level-" + level + "_z"
                                : togglebles.includes(key)
                                ? "grid-row_z level-" + level + "_z_tglb"
                                : "grid-row_z level-" + level + "_z"
                        }`}
                    >
                        <div
                            className={`${key != "invoice_items" ? "grid-cell_z key_z" : "grid-cell_z key_items"}`}
                            data-original-key={key}
                            style={{
                                fontFamily: "Urbanist, Roboto",
                                margin: "0px",
                                padding: "5px",
                                display: "flex",
                                justifyContent: "space-between"
                            }}
                        >
                            <div>{key.replace(/_/g, " ").replace(/\b\w/g, char => char.toUpperCase())}</div>
                        </div>

                        <div
                            className={`${key != "invoice_items" ? "grid-cell_z value_z" : "grid-cell_z value_items"}`}
                            style={{
                                margin: "0px",
                                padding: "3px",
                                display: "block",
                                width: "100%"
                            }}
                        >
                            {typeof value === "object" && value !== null ? (
                                Array.isArray(value) && key != "page_type" ? (
                                    <div
                                        className="array-container_z"
                                        style={{
                                            fontFamily: "Urbanist, Roboto",
                                            marginTop: "0px",
                                            marginBottom: "0px",
                                            borderLeft: "5px solid #f07e11",
                                            borderTop: "1px solid #f07e11",
                                            marginLeft: "0px"
                                        }}
                                    >
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "start" }}>
                                                <div className="add-icon_z" onClick={() => handleAddItem(currentPath)}>
                                                    <IoAddCircleOutline size={18} />
                                                </div>
                                                <div
                                                    className="customize-icon_z"
                                                    onClick={e => {
                                                        setInstructCalloutTargetElement(e.currentTarget);
                                                        openCallout(pathToString(currentPath));
                                                    }}
                                                >
                                                    <BiCustomize size={16} />
                                                </div>
                                            </div>

                                            <div
                                                className="expand-items-icon_z"
                                                style={{ marginTop: "1px", cursor: "pointer", marginRight: "5px" }}
                                                onClick={() => {
                                                    toggleAllRows();
                                                }}
                                            >
                                                {isExpanded ? (
                                                    <FaChevronCircleUp size={22} color="#039590" />
                                                ) : (
                                                    <FaChevronCircleDown size={22} color="#039590" />
                                                )}
                                            </div>
                                        </div>

                                        {value.map((item, index) => {
                                            const arrayItemPath = [...currentPath, index];
                                            const arrayItemPathAsString = pathToString(arrayItemPath);
                                            return (
                                                <div
                                                    key={arrayItemPathAsString}
                                                    className={`${item["total_mismatch"] == true ? "array-container-mismatched_z" : "array-container_z"}`}
                                                    style={{
                                                        fontFamily: "Urbanist, Roboto",
                                                        marginTop: "4px",
                                                        marginBottom: "4px",
                                                        borderLeft: "5px solid #f07e11",
                                                        borderTop: "1px solid #f07e11",
                                                        width: "100%",
                                                        paddingLeft: "4px"
                                                    }}
                                                >
                                                    <div
                                                        className="delete-icon_z"
                                                        title="Delete Item"
                                                        onClick={() => handleDelete(arrayItemPathAsString, arrayItemPath)}
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        <MdDelete size={15} />
                                                    </div>
                                                    <div
                                                        className="item-expand-icon_z"
                                                        title="Toggle Compact View"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            toggleItems(arrayItemPathAsString?.replace(/>/g, "-"), togglebles);
                                                        }}
                                                    >
                                                        <BsChevronExpand size={16} />
                                                    </div>

                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                        <div style={{ flex: 1 }}>{renderGrid(item, arrayItemPath, level + 1)}</div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <div className="object-container">{renderGrid(value, currentPath, level + 1)}</div>
                                )
                            ) : (
                                <div className="value-link_z">
                                    {editingPaths[pathAsString] ? (
                                        <input
                                            type="text"
                                            className="value-input_z"
                                            value={editingValues[pathAsString]}
                                            onChange={e => handleInputChange(pathAsString, e.target.value)}
                                            onKeyPress={e => {
                                                if (e.key === "Enter") {
                                                    saveEditing(pathAsString, currentPath);
                                                }
                                            }}
                                            onBlur={() => cancelEditing(pathAsString)}
                                            autoFocus
                                            style={{
                                                fontFamily: "Urbanist, Roboto",
                                                fontSize: "12px",
                                                marginLeft: "0px",
                                                marginTop: "0px",
                                                marginBottom: "0px",
                                                paddingLeft: "2px",
                                                paddingRight: "2px",
                                                paddingTop: "1px",
                                                width: "100%"
                                            }}
                                        />
                                    ) : (
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                marginLeft: "0px",
                                                width: "100%"
                                            }}
                                        >
                                            <div
                                                className="value-text_z"
                                                style={{
                                                    marginLeft: "0px",
                                                    marginTop: "0px",
                                                    marginBottom: "0px",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",

                                                    padding: "2px",
                                                    cursor: onDataHighlight ? "pointer" : "default"
                                                }}
                                                onClick={onDataHighlight ? () => onDataHighlight(String(value)) : undefined}
                                            >
                                                {String(value)}
                                            </div>

                                            {!non_editables.includes(key) && (
                                                <div style={{ display: "flex", flexDirection: "row" }}>
                                                    <div
                                                        className="customize-icon"
                                                        title="Add Custom Instructions"
                                                        onClick={e => {
                                                            setInstructCalloutTargetElement(e.currentTarget);
                                                            openCallout(pathToString(currentPath));
                                                        }}
                                                    >
                                                        <BiCustomize size={15} />
                                                    </div>
                                                    <div
                                                        className="edit-icon_z"
                                                        title="Edit Field Value"
                                                        onClick={() => startEditing(pathAsString, String(value))}
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        <TbEditCircle />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                );
            });
    };

    return (
        <div
            style={{
                fontFamily: "Urbanist, Roboto",
                padding: "5px",
                borderRadius: "5px",
                margin: "5px"
            }}
        >
            <div
                className="overviewTableCont"
                onClick={() => {
                    toggleOverviewTable();
                }}
            >
                Items Overview Table
                <div style={{ marginTop: "3px" }}>
                    {isOverviewTableExpanded ? <FaChevronCircleUp size={20} color="#039590" /> : <FaChevronCircleDown size={20} color="#039590" />}
                </div>
            </div>
            {
                <>
                    {(() => {
                        let calcTotal = 0;
                        let recogTotal = 0;
                        try {
                            calcTotal = gridData?.invoice_items?.reduce((sum: number, item: any) => sum + Number(item.calculated_total_price), 0).toFixed(2);
                            recogTotal = gridData?.invoice_items?.reduce((sum: number, item: any) => sum + Number(item.total_price), 0).toFixed(2);
                        } catch (e) {}

                        let diff = Math.abs(Number(calcTotal) - Number(recogTotal));

                        return (
                            <div className={`table-container ${isOverviewTableExpanded ? "expanded" : "hiding"}`}>
                                <table style={{ borderCollapse: "collapse", width: "100%" }} className="table-slide">
                                    <thead>
                                        <tr>
                                            <th className="detTableCell">Index</th>
                                            <th className="detTableCell">Calculated Total</th>
                                            <th className="detTableCell">Total Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(gridData?.invoice_items || [])?.map((item: any, index: number) =>
                                            Math.abs(item.calculated_total_price - item.total_price) > 10 ? (
                                                <tr key={index} className={"mismatchTable"}>
                                                    <td className="detTableVal">{index + 1}</td>
                                                    <td className="detTableVal">{item.calculated_total_price}</td>
                                                    <td className="detTableVal">{item.total_price}</td>
                                                </tr>
                                            ) : diff > 5 ? (
                                                <tr key={index}>
                                                    <td className="detTableVal">{index + 1}</td>
                                                    <td className="detTableVal">{item.calculated_total_price}</td>
                                                    <td className="detTableVal">{item.total_price}</td>
                                                </tr>
                                            ) : (
                                                <tr key={index}>
                                                    <td className="detTableVal">{index + 1}</td>
                                                    <td className="detTableVal">{item.calculated_total_price}</td>
                                                    <td className="detTableVal">{item.total_price}</td>
                                                </tr>
                                            )
                                        )}
                                        <tr className="detTableTots">
                                            <td className="detTableVal">Total</td>
                                            <td className="detTableVal">{calcTotal}</td>
                                            <td className="detTableVal">{recogTotal}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className={"detInvInfo"}>NET Values</div>
                            </div>
                        );
                    })()}
                </>
            }

            <div className="invoiceDataTitle">Detailed Invoice Data</div>

            <TooltipHost
                content="ID determining applicability of customized instructions. Instructions configured on this Page, will be applied for this recognized Contractor ID"
                directionalHint={DirectionalHint.leftCenter}
            >
                <div style={{ marginTop: "13px", marginLeft: "2px", font: "Urbanist, Roboto", fontSize: "12px", color: "#374955" }}>
                    <span style={{ fontWeight: "bold" }}>Contractor ID:</span> {gridData["contractor_vat_identification"]}
                </div>
            </TooltipHost>
            <div className="statusMessage">
                <b>State:</b>
                {gridData["status_message"]}
            </div>
            {gridData && (
                <div style={{ fontFamily: "Urbanist, Roboto" }} className="json-grid_z">
                    {renderGrid(gridData)}
                </div>
            )}
            {isInstructionCalloutOpen?.isOpen && (
                <Callout target={instructCalloutTargetElement} onDismiss={() => setIsInstructionCalloutOpen(undefined)} isBeakVisible={false}>
                    <div className="callout-main-header_z">Custom Instructions</div>
                    <div className="callout-header_z">
                        <div className="callout-header_details-z">
                            <div style={{ fontWeight: "bold", marginRight: "5px" }}>Field:</div>
                            <div style={{ marginRight: "10px" }}>{isInstructionCalloutOpen?.path}</div>
                            <div style={{ fontWeight: "bold", marginRight: "5px" }}> Contractor ID: </div>
                            <div style={{ marginRight: "15px" }}>{gridData["contractor_vat_identification"]}</div>
                        </div>
                    </div>
                    <div className="callout-header_instructions-z">Add short instruction items to help AI extract data from this field. </div>
                    <div className="callout-header_instructions-z">
                        Next time when Contractor with ID
                        {gridData["contractor_vat_identification"]} is recognised, these instructions will be applied. These instructions are added to the
                        pre-defined instrutions for the field.
                    </div>
                    <div className="callout-warn-z" style={{ color: "#c26461" }}>
                        These instructions are added to the pre-defined optimized instructions for the field. Unpredictable or degraded results may occur.
                    </div>
                    <div className={"tagInputContainer"}>
                        <InstructTagInput
                            onChange={handleInstructionOp}
                            initialTags={isInstructionCalloutOpen?.instructions}
                            field={isInstructionCalloutOpen.path}
                            groupid={group?.selectionId || ""}
                            userid={loggedInUser?.userId || ""}
                            contractor_id={gridData["contractor_vat_identification"]}
                        />
                    </div>
                </Callout>
            )}
        </div>
    );
};

export default JsonGrid;
