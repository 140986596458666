import React, { useEffect, useState } from "react";
import { IRefObject, ITextField } from "@fluentui/react";

interface Props {
    trgt: any;
}

const IframeListener = ({ trgt }: Props) => {
    useEffect(() => {
        const handlePostMessage = (event: any) => {
            console.log("Received message:", event.data);
            if (trgt.current) {
                (trgt.current as any).focus();
            }
        };

        // Add the event listener
        window.addEventListener("message", handlePostMessage);

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener("message", handlePostMessage);
        };
    }, []); // Empty dependency array to run only once

    return <div></div>;
};

export default IframeListener;
