import { useState, useEffect, useRef, useContext } from "react";
import { pdfjs } from "react-pdf";
import * as sdk from "microsoft-cognitiveservices-speech-sdk";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import styles from "./XplrrPDF.module.css";
import JsonGridGen from "../../components/JsonGridGen/JsonGridGen";
import { GlobalContext } from "../../GlobalContext";
import { ThemeProvider, Callout, DirectionalHint, IconButton } from "@fluentui/react";
import JsonGrid from "../JsonGrid/JsonGrid";
import { HiOutlineVolumeOff, HiOutlineVolumeUp } from "react-icons/hi";
import { HiMiniPlayPause } from "react-icons/hi2";
import customTheme from "./../../theme/customTheme";
import { MdDownloading } from "react-icons/md";
import { fetchSpeechAOI, getClearTextForAudio, getVoiceVersion, prepSsml } from "../../api/aud";
import { FaWindowClose } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { gXplrrSpaceOrder, gXplrrFileOrder, gXplrrContentListFileOrder, gXplrrSpaceIndex, gXplrrExtendedFileList, gloggedinuser } from "../../interfaces";
import { RiArrowRightWideLine } from "react-icons/ri";
import smoothScrollIntoView from "smooth-scroll-into-view-if-needed";
import PageSelector from "../PageSelector/PageSelector";
import XplrrBOXPDF from "../XplrrBOXPDF/XplrrBOXPDF";
import { gcontentlist, gfile, gXplrrCitationChangeCallback } from "../../interfaces";
import _, { set } from "lodash";
import FileDropdown from "../FileDropdown/FileDropdown";
import { pb_excl } from "../../lsts";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
interface TextItem {
    pageIndex: number;
    pageNumber: number;
    itemIndex: number;
    str: string;
}
interface ViewerPDFProps {
    startPage: string;
    citationFileId: string;
    xplrrCitationChangeCallback: (callBackProps: gXplrrCitationChangeCallback) => void;
    fileId: string;
    refPageNum: string;
    isCitation: boolean;
    rndo?: string;
    searchString: string;
    activeGroupState: string;
    fileList: gfile[];
    shouldPlayAudio: string;
    isIframe: boolean;
    onXplrrPDFDismiss: () => void;
    onDataHighlight: (searchString: string) => void;
    isLib: string;
    loggedInUser: gloggedinuser;
    onInvValueUpdate: () => void;
}

// fileId			PARAM
// activeFileId		CURRENT FILE TO LOAD		[state]
// flId				CITATION FILE [state]

const XplrrPDF = ({
    startPage,
    citationFileId,
    xplrrCitationChangeCallback,
    fileId,
    refPageNum,
    isCitation,
    rndo,
    searchString,
    activeGroupState,
    fileList,
    shouldPlayAudio,
    isIframe,
    onXplrrPDFDismiss,
    onDataHighlight,
    isLib,
    loggedInUser,
    onInvValueUpdate
}: ViewerPDFProps) => {
    const [fileListStateVar, setFileListStateVar] = useState<gfile[]>([]); // Files to Showcase
    const [file, setFile] = useState<gfile>(); // Current File
    const [numPages, setNumPages] = useState<number>(); // Number of Doc Pages

    const [fileMap, setFileMap] = useState<gXplrrSpaceOrder>({}); // Full File⇒Item Map
    const [contentFileOrder, setContentFileOrder] = useState<gXplrrContentListFileOrder>({});
    const [currentPage, setCurrentPage] = useState(0);
    const [audioPage, setAudioPage] = useState(0);
    const [referencePage, setReferencePage] = useState(0);
    const [manualSection, setManualSecton] = useState<number>();
    const [activeCitationState, setActiveCitationState] = useState<string>();
    const [isContentTableShowing, setIsContentTableShowing] = useState<boolean>(false);
    const [flId, setFlId] = useState<string>();
    const [activeFileId, setActiveFileId] = useState<string>();
    const [docRendered, setDocRendered] = useState(false);
    const [pageLoaded, setPageLoaded] = useState(true);
    const [perFilesPerSectionStateMap, setPerFilesPerSectionStateMap] = useState<Map<string, Map<string, boolean>>>(new Map());
    const [contentRefreshTriggerIncrement, setContentRefreshTriggerIncrement] = useState(0);
    const { sourceHighlighting, docAudio, selectedGroup } = useContext(GlobalContext);

    // AUDIO
    const [isPlaying, setIsPlaying] = useState(false);
    const [isLoadingAudio, setIsLoadingAudio] = useState(false);
    const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
    const synthesizerRef = useRef<sdk.SpeechSynthesizer | null>(null);
    const audioRef = useRef<sdk.SpeakerAudioDestination | null>(null);

    // fileId			PARAM
    // activeFileId		CURRENT FILE TO LOAD		[state]
    // flId				CITATION FILE [state]

    // Runs from Outside [Citations]
    useEffect(() => {
        // Prevent Runs before the Space has aligned
        if (activeGroupState != selectedGroup.selectionId) return;
        if (fileList.length == 0) return;
        setActiveCitationStateFunc(citationFileId);

        if (fileId != flId || fileId != activeFileId) {
            window.globalBase64String = "";
            window.globalPDFPageDimensions = [];

            setFlId(fileId);
            setActiveCitationState(citationFileId);

            const fileToLoad = fileList.find(f => f.fileid == fileId);
            setActiveFileId(fileId);
            setFile(fileToLoad);

            setCurrentPage(parseInt(startPage));
            setReferencePage(parseInt(startPage));
        } else {
            setReferencePage(parseInt(startPage));
            setHighlightsPerfAfterLoad();
        }
    }, [rndo]);

    // UE - activeFileId
    useEffect(() => {
        if (activeGroupState != selectedGroup.selectionId) return;
        if (fileList.length == 0) return;
        if (activeCitationState && fileId == flId && activeFileId == flId) setHighlightsPerf();

        const fileMap: gXplrrSpaceOrder = fileMapGen(fileList); // Generate Content List File Order and Item Opened Map
        const cachedFileOrder: gXplrrContentListFileOrder = setContentFileOrderToState(fileList); // Order Items, based on Local State Cache or Default
        const extendedFileList: gXplrrExtendedFileList[] = fileList.map((flfile, flIndex) => {
            return {
                ...flfile,
                globalXplrrOrder: cachedFileOrder[flfile.fileid] == null ? fileMap[parseInt(flfile.fileid)].order : cachedFileOrder[flfile.fileid]
            };
        });
        setFileMap(fileMap); // Preprocess - Need data for Dynamic Content List
        setFileListStateVar(extendedFileList.sort((a, b) => a.globalXplrrOrder - b.globalXplrrOrder));
    }, [activeFileId]);

    // UE - contentFileOrder
    useEffect(() => {
        if (activeGroupState != selectedGroup.selectionId) return;
        if (fileList.length == 0) return;

        // Preserve File Order to Local Cache
        if (Object.keys(contentFileOrder).length > 0) {
            const fileOrderCache = localStorage.getItem("xprrContentListFileOrder1");
            if (fileOrderCache) {
                const fullFileOrderCacheParsed: gXplrrSpaceIndex = JSON.parse(fileOrderCache);
                fullFileOrderCacheParsed[selectedGroup.selectionId] = contentFileOrder;
                localStorage.setItem("xprrContentListFileOrder1", JSON.stringify(fullFileOrderCacheParsed));
            } else {
                const fullFileOrderCache: gXplrrSpaceIndex = {};
                fullFileOrderCache[selectedGroup.selectionId] = contentFileOrder;
                localStorage.setItem("xprrContentListFileOrder1", JSON.stringify(fullFileOrderCache));
            }
        }
    }, [contentFileOrder]);

    useEffect(() => {
        stopSpeech();
    }, [shouldPlayAudio]);

    useEffect(() => {
        setContentRefreshTriggerIncrement(prevValue => prevValue + 1);
    }, [perFilesPerSectionStateMap]);

    const setHighlightsPerf = async () => {
        // This is a Navigation Reguest without Highlights
        if (!searchString) {
            import.meta.env.DEV === true && console.log("hghlt_no_srch_str", "flId", flId, "searchString", searchString);
            setCurrentPage(parseInt(startPage));
            scrollCustomAnimPage(parseInt(startPage));
            return;
        }

        removeFontColorToMatchesRed();

        if (activeCitationState && isCitation && sourceHighlighting == true) {
            applyHighlightsPerf();
        }
        import.meta.env.DEV === true && console.log("srch_strng", searchString);
        const allResults = await setHighlightsGloV3();
        const allResultsNull = allResults?.every(result => result === null);
        if (allResultsNull) {
            import.meta.env.DEV === true && console.log(`hghlt_scrll_no_smth[${startPage}]`);
            if (startPage) {
                import.meta.env.DEV === true && console.log("scrl_to_pg");
                scrollToPageNoSmooth(parseInt(startPage));
            }
        }
        reendableUserScrollHandling(100);
    };
    const setHighlightsPerfAfterLoad = async () => {
        // This is a Navigation Reguest without Highlights
        if (!searchString) {
            //import.meta.env.DEV === true && console.log("stHghlghtsPrfAftrLd¦NO_SEARCH_STRING", "flId", flId, "searchString", searchString);
            setCurrentPage(parseInt(startPage));
            scrollCustomAnimPage(parseInt(startPage));
            return;
        }

        removeFontColorToMatchesRed();
        if (activeCitationState && sourceHighlighting == true) applyHighlightsPerf();

        const allResults = await setHighlightsGloV3();
        const allResultsNull = allResults?.every(result => result === null);
        if (allResultsNull) {
            if (startPage) {
                scrollToPageNoSmooth(parseInt(startPage));
                reendableUserScrollHandling();
            }
        }
        setPageLoaded(true);
    };
    const setHighlightsGloV3 = async () => {
        if (file?.indexchunks) {
            let highlightPages: number[] = findRange([...file.indexchunks, file.pagedimensions.length], parseInt(startPage));
            if (highlightPages.length > 0 && highlightPages[0] > 1) {
                // Add one more pages infront. Need to deep dive in index/number logic
                highlightPages = [highlightPages[0] - 1].concat(highlightPages);
            }
            window.globalHiglightFound = false;
            const promises = highlightPages.map((page, index) => setHighlightsV3(page, index, highlightPages.length));

            const results = await Promise.all(promises);
            const allResultsNull = results?.every(result => result === null);
            if (allResultsNull) {
                const results = await findMatchingSequenceRGX(highlightPages);
                return [results];
            } else {
                return results;
            }
        }
    };
    const findMatchingSequenceRGX = async (pg: number[]) => {
        import.meta.env.DEV === true && console.log("fndMtchngSqncRGX", pg);
        const filterString = (str: string | undefined): string => {
            return (str || "")
                .toLowerCase()
                .replace(/[^а-яa-z0-9]/gi, "")
                .trim();
        };

        const filteredTargetString = filterString(searchString);

        let rgx; // !!! INV SPECIFIC
        if (filteredTargetString.length < 3 || /^\d+$/.test(filteredTargetString) == true) {
            rgx = new RegExp(filteredTargetString, "g");
        } else {
            rgx = prepareRegex(filteredTargetString);
        }

        import.meta.env.DEV === true && console.log("fltr_str", filteredTargetString, rgx);

        const selectorPG = pg.map(pg => `.pdf_itm_${pg - 1}`).join(", ");
        const items = document.querySelectorAll(selectorPG);
        const itemsArray = Array.from(items);

        let processedText = "";
        let elementMap: highLightItem[] = [];

        for (let index = 0; index < itemsArray.length; index++) {
            const item = itemsArray[index] as HTMLElement;
            const originalItemText = item.textContent?.trim();
            const itemText = filterString(originalItemText);
            if (itemText.length > 0) {
                elementMap.push({
                    startIndex: processedText.length,
                    endIndex: processedText.length + itemText.length - 1,
                    element: item as HTMLElement,
                    processedText: itemText
                });
                processedText += itemText;
            }
        }

        let matches = [...processedText.matchAll(rgx)];
        if (isLib == "lib" && selectedGroup.invoicesgroup == 1) {
            matches = matches.filter(
                match =>
                    (match && match.length < 2 && match[0].length <= searchString.length) ||
                    (match && match.length > 1 && match[1].length <= searchString.length)
            );
        }

        matches.forEach(match => {
            let startIndex = match.index || 0;
            const endIndex = startIndex + match[0].length - 1;

            const matchingElements = elementMap.filter(
                item =>
                    (item.startIndex <= startIndex && startIndex <= item.endIndex) ||
                    (item.startIndex <= endIndex && endIndex <= item.endIndex) ||
                    (startIndex <= item.startIndex && item.endIndex <= endIndex)
            );
            //import.meta.env.DEV === true && console.log(`fndMtchngSqncRGX¦MATCH ⇒ "${match[0]}" FOUND_IN ${matchingElements.length} Elements:`);
            matchingElements.forEach((item, index) => {
                if (index == matchingElements.length - 1) {
                    //import.meta.env.DEV === true && console.log("scrll_hghlght", pg, matchingElements);
                    if (window.globalHiglightFound == true) {
                        import.meta.env.DEV === true && console.log("glbalHglghtFnd_not_true", window.globalHiglightFound);
                    } else {
                        //import.meta.env.DEV === true && console.log("glbalHglghtFnd", window.globalHiglightFound, "true");
                        window.globalHiglightFound = true;
                        try {
                            let itmPg = parseInt(item.element.id.split("_")[1]);
                            //import.meta.env.DEV === true && console.log("SCROLL_TO_Highlight⇒ PG Extracted from Element ID", item.element.id);

                            let skndEl = item.element;
                            if (index > 0 && skndEl.innerText.length < 5) skndEl = matchingElements[index - 1].element;

                            scrollCustomAnimElement(itmPg, skndEl, skndEl);
                        } catch {
                            //import.meta.env.DEV === true && console.log("errr_highlight⇒cannot determine item page by item id", item.element);
                            smoothScrollIntoView(item.element as HTMLElement, { behavior: "smooth", block: "center" });
                        }
                    }
                }
                item.element.classList.add(styles.citHglghtrRed);
            });
        });

        if (matches.length > 0 && matches[0].index !== undefined) {
            return { startIndex: matches[0].index, endIndex: matches[0].index + matches[0][0].length - 1 };
        } else {
            return null;
        }
    };
    const setHighlightsV3 = async (page: number, i: number, lignth: number) => {
        //import.meta.env.DEV === true && console.log("¦stHghlghtsV3¦page", page, "¦i¦", i);
        await waitForAllPDFItems(page - 1, 50);
        if (window.globalHiglightFound == false) {
            if (i == lignth - 1) {
                const difBetweenSrcAndTrgPage = currentPage - page;
                if (Math.abs(difBetweenSrcAndTrgPage) > 5) {
                    if (difBetweenSrcAndTrgPage < 0) {
                        import.meta.env.DEV === true && console.log(`scrollToPage¦FROM ${currentPage} ⇒ ${currentPage + 1}`);
                        scrollToPageCSTM(currentPage + 1);
                    } else {
                        import.meta.env.DEV === true && console.log(`scrollToPage¦FROM ${currentPage} ⇒ ${currentPage - 1}`);
                        scrollToPageCSTM(currentPage - 1);
                    }
                }
            }
        }
        const result = await findMatchingSequenceRGX([page]);
        return result;
    };
    const setContentFileOrderToState = (fileList: gfile[]) => {
        const fileOrderCache = localStorage.getItem("xprrContentListFileOrder1");

        if (fileOrderCache) {
            const fullFileOrderCacheParsed: gXplrrSpaceIndex = JSON.parse(fileOrderCache);
            const fileOrderCacheParsed: gXplrrContentListFileOrder = fullFileOrderCacheParsed[selectedGroup.selectionId];

            //import.meta.env.DEV === true && console.log("XPLRR¦setContentFileOrderToState⇒fileOrderCacheParsed", fileOrderCacheParsed);
            if (fileOrderCacheParsed && Object.keys(fileOrderCacheParsed)?.length > 0) {
                setContentFileOrder(fileOrderCacheParsed);
                return fileOrderCacheParsed;
            } else {
                const defaultOrder = getDefaultFileOrderFromCache(fileList);
                setContentFileOrder(defaultOrder);
                //import.meta.env.DEV === true && console.log("XPLRR¦setContentFileOrderToState⇒No fileOrderCache", defaultOrder);
                return defaultOrder;
            }
        } else {
            const defaultOrder = getDefaultFileOrderFromCache(fileList);
            //import.meta.env.DEV === true && console.log("XPLRR¦setContentFileOrderToState⇒No fileOrderCache", defaultOrder);
            setContentFileOrder(defaultOrder);
            return defaultOrder;
        }
    };
    const getDefaultFileOrderFromCache = (fileList: gfile[]) => {
        const defaultOrder: gXplrrContentListFileOrder = {};
        fileList.map((flfile, flIndex) => {
            defaultOrder[flfile.fileid] = flIndex;
        });
        import.meta.env.DEV === true && console.log("XPLRR¦getDefaultFileOrderFromCache", defaultOrder);
        return defaultOrder;
    };
    //Callbackj from PDXplrr To Determine Current Page on Scroll
    const handleScroll = (pageNumCur: number) => {
        setCurrentPage(pageNumCur);
    };
    const scrollCustomAnimPage = (pg: number) => {
        if (pg > currentPage + 2) {
            //window.globalUserScrollLazyLoadEnabled = false;
            import.meta.env.DEV === true && console.log("scrllCstmAnmPge [pg > currentPage]", pg, currentPage);
            scrollToPageNoSmooth(pg - 2);
            setTimeout(() => scrollToPageDefault(pg), 50);
        } else if (pg < currentPage - 2) {
            //window.globalUserScrollLazyLoadEnabled = false;
            import.meta.env.DEV === true && console.log("scrllCstmAnmPge [pg < currentPage]", pg, currentPage);
            scrollToPageNoSmooth(pg + 2);
            setTimeout(() => scrollToPageDefault(pg), 50);
        } else {
            import.meta.env.DEV === true && console.log("scrllCstmAnmPge [pg = currentPage]", pg, currentPage);
            setTimeout(() => scrollToPageDefault(pg), 50);
        }
        reendableUserScrollHandling();
    };
    const scrollCustomAnimElement = (pg: number, el: HTMLElement, scndEl: HTMLElement) => {
        if (pg > currentPage + 2) {
            //window.globalUserScrollLazyLoadEnabled = false;
            //import.meta.env.DEV === true && console.log("scrllCstmAnmElmnt [pg >>>>>> currentPage]", pg, currentPage, el);

            scrollToPageNoSmooth(pg - 2);
            setTimeout(() => {
                //import.meta.env.DEV === true && console.log("SMTH_SCRL_TMOUT", pg, currentPage, el);
                smoothScrollIntoView(el, { behavior: "smooth", block: "center" });

                setTimeout(() => {
                    smoothScrollIntoView(el, { behavior: "smooth", block: "center" });
                }, 500);
            }, 50);
        } else if (pg < currentPage - 2) {
            //window.globalUserScrollLazyLoadEnabled = false;
            //import.meta.env.DEV === true && console.log("scrllCstmAnmElmnt [pg <<<<<<< currentPage]", pg, currentPage, el);
            scrollToPageNoSmooth(pg + 2);
            setTimeout(() => {
                //import.meta.env.DEV === true && console.log("SMTH_SCRL_TMOUT", pg, currentPage, el);
                smoothScrollIntoView(el, { behavior: "smooth", block: "center" });

                setTimeout(() => {
                    smoothScrollIntoView(el, { behavior: "smooth", block: "center" });
                }, 500);
            }, 50);
        } else {
            //import.meta.env.DEV === true && console.log("scrllCstmAnmElmnt [pg ======= currentPage]", pg, currentPage, el);
            //smoothScrollIntoView(el, { behavior: "smooth", block: "center" });
            setTimeout(() => {
                //import.meta.env.DEV === true && console.log("SMTH_SCRL_TMOUT", pg, currentPage, el);
                smoothScrollIntoView(el, { behavior: "smooth", block: "center" });

                setTimeout(() => {
                    smoothScrollIntoView(el, { behavior: "smooth", block: "center" });
                }, 500);
            }, 50);
        }
        reendableUserScrollHandling(500);
    };
    const scrollToPageDefault = (pN: number) => {
        import.meta.env.DEV === true && console.log("scrollToPageDefault¦", pN);
        var node = document.querySelector(`[data-page-number="${pN}"`);
        if (node) {
            smoothScrollIntoView(node, { behavior: "smooth", block: "center" });
        }
    };
    const scrollToPageCSTM = (pN: number) => {
        import.meta.env.DEV === true && console.log("scrollToPage¦CSTM", pN);
        var node = document.querySelector(`[data-page-number="${pN}"`);
        if (node) {
            smoothScrollIntoView(node, { behavior: "smooth", block: "center" });
        }
    };
    const scrollToPageNoSmooth = (pN: number) => {
        import.meta.env.DEV === true && console.log("scrllTPageNSmth", pN);
        var node = document.querySelector(`[data-page-number="${pN}"`);
        if (node) {
            node.scrollIntoView({ behavior: "auto", block: "center" });
        }
    };
    const scrollToElementNoSmooth = (el: HTMLElement) => {
        if (el) {
            el.scrollIntoView({ behavior: "auto", block: "center" });
        }
    };
    const reendableUserScrollHandling = (tm: number = 500) => {
        setTimeout(() => (window.globalUserScrollLazyLoadEnabled = true), tm);
    };
    const scrollToPageAuto = (pN: number) => {
        var node = document.querySelector(`[data-page-number="${pN}"`);
        if (Math.abs(currentPage - pN) > 5) {
            import.meta.env.DEV === true && console.log("scrllToPgeAto¦Smooth", pN);
            scrollToElementNoSmooth(node as HTMLElement);
        } else {
            import.meta.env.DEV === true && console.log("scrllToPgeAto¦Direct", pN);
            smoothScrollIntoView(node as HTMLElement, { behavior: "smooth", block: "center" });
        }
    };
    const scrollToElementAuto = (pN: number, el: HTMLElement) => {
        if (Math.abs(currentPage - pN) > 5) {
            import.meta.env.DEV === true && console.log("scrllToPgeAto¦Smooth", pN);
            scrollToElementNoSmooth(el);
        } else {
            import.meta.env.DEV === true && console.log("scrollToElement", pN);
            smoothScrollIntoView(el, { behavior: "smooth", block: "center" });
        }
    };
    const fileMapGen = (fileList: gfile[]) => {
        let fileMap: gXplrrSpaceOrder = {};
        fileList.map((flfile, flIndex) => {
            let arrItems: gXplrrFileOrder = {
                order: flIndex,
                opened: false,
                fileItemOrder: {}
            };
            if (flfile.contentlist && flfile.contentlist.length > 0) {
                flfile.contentlist.forEach((flItem, flItemIndex) => {
                    const { currentValue, splitValues, splitLength, itemLevel, pageNumExtracted } = contentListValueNumbering(flItem);
                    arrItems.fileItemOrder[flItemIndex] = {
                        opened: false,
                        currentValue: currentValue,
                        splitValues: splitValues,
                        splitLength: splitLength,
                        itemLevel: itemLevel,
                        pageNumExtracted: pageNumExtracted
                    };
                });
            }

            fileMap[parseInt(flfile.fileid)] = arrItems;
        });
        return fileMap;
    };
    const setPages = (numP: number): void => {
        setNumPages(numP);

        window.globalTextArray = [];
        window.globalTextArrayAudio = [];
        window.globalTextMap = new Map<string, boolean>();
        window.globalPDFExtr = [];
        window.globalPDFPageMap = new Map<number, any>();
        window.globalPDFContent = [];
        window.globalAllNessesaryPDFPagesLoaded = false;
        window.globalPDFPageDimensions = [];
    };
    const removeFontColorToMatches = (): void => {
        const elements = document.getElementsByClassName(styles.citHglghtr);
        while (elements.length > 0) {
            elements[0].classList.remove(styles.citHglghtr);
        }
    };
    const removeFontColorToMatchesRed = (): void => {
        const elements = document.getElementsByClassName(styles.citHglghtrRed);
        while (elements.length > 0) {
            elements[0].classList.remove(styles.citHglghtrRed);
        }
    };
    function findRange(numbers: number[], target: number): number[] {
        numbers.sort((a: number, b: number) => a - b);
        let startIndex = numbers.findIndex((num: number) => num > target);
        let upper = startIndex === -1 ? numbers[numbers.length - 1] : numbers[startIndex];
        let endIndex = numbers.lastIndexOf(target);
        let lower = endIndex !== -1 ? numbers[endIndex] : numbers[startIndex - 1];
        if (lower === undefined || upper === undefined) {
            return []; // Return an empty array if target is out of the provided range
        }
        let result = [];
        for (let i = lower; i <= upper; i++) {
            result.push(i);
        }
        if (target === upper) {
            result.push(upper);
        }
        try {
            window.globalpdfObservableRange = result.length;
        } catch {}
        return result;
    }
    function cleanText(text: string | null) {
        if (!text) return "";
        return text.replace(/\r?\n/g, "").replace(/\s+/g, "");
    }
    const waitForPdfPage = async (index: number, maxAttempts: number = 50): Promise<true | null> => {
        const isReactPdfPage = (element: Element | null): boolean => {
            if (!element || !(element instanceof HTMLElement)) {
                return false;
            }
            return element.tagName.toLowerCase() === "div" && element.classList.contains("react-pdf__Page");
        };
        for (let attempt = 0; attempt < maxAttempts; attempt++) {
            import.meta.env.DEV === true && console.log(`PAGE LOAD CHECK ${index}`);
            const element = document.querySelectorAll(`div[data-page-number="${index}"]`);
            import.meta.env.DEV === true && console.log("EL", element);
            if (isReactPdfPage(element[0])) {
                import.meta.env.DEV === true && console.log(`PDF LOADED ${index}`);
                return true;
            }
            await new Promise(resolve => setTimeout(resolve, 100));
        }
        return null;
    };
    const waitForAllPDFItems = async (index: number, maxAttempts: number = 50): Promise<true | null> => {
        const isReactPdfPageTextRendererDone = (index: number): boolean => {
            let itemProgress = window.globalItemCounts?.get(index);
            if (itemProgress !== undefined && itemProgress.flag == true) {
                let pdfPageItemCount = document.querySelectorAll(`.pdf_itm_${index}`);
                if (pdfPageItemCount.length == itemProgress.count) {
                    return true;
                } else {
                    setReferencePage(index + 1);
                    return false;
                }
            } else {
                return false;
            }
        };
        for (let attempt = 0; attempt < maxAttempts; attempt++) {
            if (isReactPdfPageTextRendererDone(index)) return true;
            await new Promise(resolve => setTimeout(resolve, 500));
        }
        console.warn(`waitFrItms¦FAILED_RENDER_WAIT⇒ ${index} AFTER ${maxAttempts} ATTEMPTS`);
        return null;
    };
    const filterString = (str: string) => {
        return str
            .toLowerCase()
            .replace(/[^а-яa-z0-9]/gi, "") // Keep Cyrillic, Latin letters, and digits
            .trim();
    };
    interface highLightItem {
        startIndex: number;
        endIndex: number;
        element: HTMLElement;
        processedText: string;
    }
    const prepareRegex = (filterString: string) => {
        // Split the string in half
        const halfLength = Math.floor(filterString.length / 2);
        let startPart = filterString.slice(0, halfLength);
        let endPart = filterString.slice(halfLength);

        // Get the first and last 10 characters (or less if the string is shorter)
        startPart = startPart.slice(0, 5);
        endPart = endPart.slice(-5);

        // Escape special regex characters
        const escapeRegex = (str: string) => str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        startPart = escapeRegex(startPart);
        endPart = escapeRegex(endPart);

        if (isLib == "lib" && selectedGroup.invoicesgroup == 1) {
            // Greedy gets even matches within matches for invoice extract detect
            return new RegExp(`(?=(${startPart}[\\s\\S]*?${endPart}))`, "g");
        } else {
            // Doesnt get matches within matches
            return new RegExp(`${startPart}[\\s\\S]*?${endPart}`, "g");
        }
    };
    const wigglePixel = async () => {
        while (window.globalHiglightFound == false) {
            let element = document.getElementById("pdfmainid9");

            element?.scrollBy(0, 1);
            await new Promise(r => setTimeout(r, 100));
            element?.scrollBy(0, 1);
            await new Promise(r => setTimeout(r, 100));
        }
    };
    const applyHighlightsPerf = () => {
        if (file?.indexchunks) {
            const highlightPages: number[] = findRange([...file.indexchunks, file.pagedimensions.length], parseInt(startPage));
            for (let i = 0; i < highlightPages.length; i++) {
                document.querySelectorAll(`div[data-page-number="${highlightPages[i]}"] span`).forEach(span => {
                    (span as HTMLElement).classList.add(styles.citHglghtr);
                });
            }
        }
    };
    // Define the function to call after the timeout
    const handleTimeout = () => {
        import.meta.env.DEV === true && console.log("xplrr_pxf_load_done");
        reendableUserScrollHandling();

        // fileId			PARAM
        // activeFileId		CURRENT FILE TO LOAD		[state]
        // flId				CITATION FILE [state]
        setDocRendered(true);
        if (fileId == activeFileId) {
            setHighlightsPerf();
        }
        setPageLoaded(true);
    };
    const setContentList = (contentList: { title: string; dest: number }[]) => {
        import.meta.env.DEV === true && console.log("VWRPDF¦setContentList", contentList);
    };
    const getCurrentPage = () => {
        const container = document.getElementById("pdfmainid9");
        if (container) {
            let { scrollTop, clientHeight, scrollHeight } = container;
            let pageHeight = (scrollHeight ? scrollHeight : 1) / (numPages ? numPages : 1);
            let pp = Math.ceil((scrollTop + clientHeight - clientHeight / 3) / pageHeight);
            return pp;
        }
        return 0;
    };
    function contentListValueNumbering(item: gcontentlist) {
        const currentValue = item.destPageNum;

        if (item.srcText) {
            let splitValues = item.srcText.trim().replace(" .", ".").split(" ");
            let pageNum = item.destPageNum;
            if (!isNaN(Number(splitValues[splitValues.length - 1]))) {
                pageNum = parseInt(splitValues[splitValues.length - 1]);
                splitValues.pop();
            }

            if (!splitValues || splitValues.length == 0) {
                return {
                    currentValue: "",
                    splitValues: [],
                    splitLength: 0,
                    itemLevel: 0,
                    pageNumExtracted: 0
                };
            }

            return {
                currentValue: currentValue.toString(),
                splitValues: splitValues,
                splitLength: splitValues.length,
                itemLevel: (addDotIfNeeded(splitValues[0]).match(/\./g) || []).length - 1,
                pageNumExtracted: pageNum
            };
        } else {
            return {
                currentValue: "",
                splitValues: [],
                splitLength: 0,
                itemLevel: 0,
                pageNumExtracted: 0
            };
        }
    }
    const handlePageChangeFromPageSelector = (event: React.ChangeEvent<HTMLSelectElement>) => {
        window.globalUserScrollLazyLoadEnabled == true;

        setManualSecton(Number(event.target.value));

        setCurrentPage(Number(event.target.value));
        scrollToPageNoSmooth(Number(event.target.value));

        reendableUserScrollHandling();
    };
    const handleClick = (target: EventTarget, item: gcontentlist, indx: number) => {
        scrollToPageNoSmooth(item.destPageNum);
        setCurrentPage(item.destPageNum);
        reendableUserScrollHandling();
    };
    const handleFileClick = (target: EventTarget, fl: gfile, flIndex: number) => {
        import.meta.env.DEV === true && console.log("handleFileClick¦setActiveFileId", fl.fileid);

        window.globalUserScrollLazyLoadEnabled == true;

        setFile(fl);
        setActiveFileIdFunction(fl.fileid);

        setCurrentPage(1);

        reendableUserScrollHandling(100);
    };
    const addDotIfNeeded = (str: string) => {
        return str.endsWith(".") ? str : str + ".";
    };
    const expandCollapseSection = (flId: string, srcText: string) => {
        setPerFilesPerSectionStateMap(prevMap => {
            const newMap = new Map(prevMap);
            const fileMap = newMap.get(flId) || new Map();
            const newFileMap = new Map(fileMap);

            newFileMap.set(srcText, !fileMap.get(srcText));
            newMap.set(flId, newFileMap);

            return newMap;
        });
    };
    function renderLinkData() {
        if (!file || !fileMap || (fileMap && Object.keys(fileMap).length < 1)) return null;

        let weAreOver = true;
        let weAreOverRef = true;
        let setActive = false;
        let setActiveRef = false;
        const fllmnt: JSX.Element[] = [];
        let fl = file;

        if (fl.fileid == activeFileId) {
            const linkData = fl.contentlist;
            let linkPageNumTracking = 0;
            let linkDataLength = linkData.length;
            let lvlZeroItem: gcontentlist;

            const minItemLevel = Math.min(...Object.values(fileMap[parseInt(fl.fileid)]?.fileItemOrder).map(item => item["itemLevel"]));
            linkData?.map((item, index) => {
                if (item.srcText !== "") {
                    const { currentValue, splitValues, splitLength, itemLevel, pageNumExtracted } = fileMap[parseInt(fl.fileid)]?.fileItemOrder[index];
                    const {
                        currentValue: currentValueNext,
                        splitValues: splitValuesNext,
                        splitLength: splitLengthNext,
                        itemLevel: itemLevelNext,
                        pageNumExtracted: pageNumExtractedNext
                    } = fileMap[parseInt(fl.fileid)].fileItemOrder[index + 1 < linkDataLength ? index + 1 : index];
                    let nextItem = linkData[index + 1 < linkDataLength ? index + 1 : index];

                    if (splitValues.join("").length < 3) return;
                    if (item.destPageNum < linkPageNumTracking) return;

                    linkPageNumTracking = item.destPageNum;
                    let samePageLink = false;
                    if (index > 0 && linkData[index - 1].destPageNum == item.destPageNum) samePageLink = true;
                    if (weAreOver != currentPage > item.destPageNum) setActive = true;

                    weAreOver = currentPage > item.destPageNum;

                    if (weAreOverRef != referencePage > item.destPageNum) setActiveRef = true;

                    weAreOverRef = referencePage > item.destPageNum;

                    let itemClass = `${styles.pdfContListNormal} ${setActive == true ? styles.pdfContListSelected : ""} ${
                        setActiveRef == true ? styles.pdfContListBold : ""
                    }`;
                    let contClass = `${itemLevel == 0 ? styles.contentItem : itemLevel == 1 ? styles.contentItemSub : styles.contentItemSub2}`;
                    if (itemLevel == minItemLevel || 0) {
                        lvlZeroItem = item;
                        fllmnt.push(
                            <div className={styles.itemContBacka} key={index}>
                                <div key={index} className={itemClass}>
                                    <div onClick={event => handleClick(event.target, item, index)} className={contClass}>
                                        <div className={styles.contentItemNumbeing}>{splitValues[0]}</div>
                                        <div className={styles.contentItemText}>{splitValues.slice(1).join(" ")}</div>
                                        <div
                                            className={`${itemLevelNext > itemLevel ? styles.contentPageNum : styles.contentPageNumNoExpandNeeded}`}
                                            onClick={e => {
                                                if (itemLevelNext > itemLevel) {
                                                    e.stopPropagation();
                                                    expandCollapseSection(fl.fileid, item.srcText);
                                                }
                                            }}
                                        >
                                            <div>{pageNumExtracted}</div>
                                            {itemLevelNext > itemLevel ? (
                                                perFilesPerSectionStateMap.get(fl.fileid)?.get(lvlZeroItem.srcText) == true ? (
                                                    <div className={styles.expandContentSubItemsIcon}>
                                                        <IoIosArrowDown size={15} />
                                                    </div>
                                                ) : (
                                                    <div className={styles.expandContentSubItemsIcon}>
                                                        <RiArrowRightWideLine size={15} />
                                                    </div>
                                                )
                                            ) : (
                                                <div className={styles.expandContentSubItemsNoIcon}></div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    } else {
                        if (perFilesPerSectionStateMap.get(fl.fileid)?.get(lvlZeroItem?.srcText) == true) {
                            if (itemLevel == 1) {
                                contClass = `${styles.contentItemSub}`;
                                fllmnt.push(
                                    <div className={styles.itemContBacka} key={index}>
                                        <div key={index} className={itemClass}>
                                            <div onClick={event => handleClick(event.target, item, index)} className={contClass}>
                                                <div className={styles.contentItemNumbeing}>{splitValues[0]}</div>
                                                <div className={styles.contentItemText}>{splitValues.slice(1).join(" ")}</div>
                                                <div className={styles.contentPageNumNoExpandNeeded}>{pageNumExtracted}</div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            } else if (itemLevel >= 2) {
                                contClass = `${styles.contentItemSub2}`;

                                fllmnt.push(
                                    <div key={index}>
                                        <div className={styles.itemContBacka} key={index}>
                                            <div key={index} className={itemClass}>
                                                <div onClick={event => handleClick(event.target, item, index)} className={contClass}>
                                                    <div className={styles.contentItemNumbeing}>{splitValues[0]}</div>
                                                    <div className={styles.contentItemText}>{splitValues.slice(1).join(" ")}</div>
                                                    <div className={styles.contentPageNumNoExpandNeeded}>{pageNumExtracted}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                        }
                    }
                    if (setActive == true) setActive = false;
                    if (setActiveRef == true) setActiveRef = false;
                }
            });
        }
        return fllmnt;
    }

    const playSpeech = () => {
        if (audioRef.current && audioPage == currentPage) {
            audioRef.current.resume();
            setIsPlaying(true);
        } else {
            audioRef.current = null;
            setIsLoadingAudio(true);
            fetchSpeechAudio(currentPage);
        }
    };
    const stopSpeech = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            setIsPlaying(false);
        }
    };
    const handleAudioAOI = async (page: number, retryCount = 0) => {
        setCurrentPage(page);
        let text = getClearTextForAudio(page);
        if (text && text.trim().length > 0) {
            let aud = await fetchSpeechAOI(text);
            if (aud) {
                aud.loop = false;
                aud.onended = () => {
                    if (numPages && page + 1 < numPages) {
                        scrollToPageDefault(page + 1);
                        handleAudioAOI(page + 1);
                    }
                };
                setAudio(aud);
                aud.play();
                setIsLoadingAudio(false);
                setIsPlaying(true);
            }
        } else {
            if (numPages && currentPage < numPages) {
                scrollToPageDefault(page + 1);
                handleAudioAOI(page + 1);
            }
        }
    };
    const fetchSpeechAudio = async (page: number, retryCount = 0) => {
        const reg = import.meta.env.VITE_STT_R;
        const apk = import.meta.env.VITE_STT_K;

        setCurrentPage(page);
        setAudioPage(page);

        const items = document.querySelectorAll(`.pdf_itm_${page - 1}`);
        const text = Array.from(items)
            .filter(
                item =>
                    item.textContent != null && // Exclude if string is empty
                    !/^\d+$/.test(item.textContent) && // Exclude if string is only digits
                    !pb_excl.includes(item.textContent.toLowerCase().trim()) && // Exclude if string is in the exclusion array
                    item.textContent.trim() !== "|"
            )
            .map(item => item.textContent)
            .join(" ")
            .replace("CSoft", "Сисофт");

        if (text && text.trim().length > 0) {
            try {
                let player = new sdk.SpeakerAudioDestination();
                player.onAudioEnd = function (_) {
                    if (numPages && page + 1 < numPages) {
                        scrollToPageDefault(page + 1);
                        player.close();
                        fetchSpeechAudio(page + 1);
                    }
                };
                player.onAudioStart = function (_) {
                    import.meta.env.DEV === true && console.log("onAudioStart");
                };
                //const containsCyrillic = /[а-яА-ЯЁё]/.test(text);
                //let voiceP = "en-US-AvaNeural";
                //let lcle = "en-US";
                //if (containsCyrillic) {
                //    voiceP = "bg-BG-KalinaNeural";
                //    lcle = "bg-BG";
                //}
                let voiceVer = getVoiceVersion(text);
                let voiceP = voiceVer.voice;
                let lcle = voiceVer.language;

                audioRef.current = player;
                var audioConfig = sdk.AudioConfig.fromSpeakerOutput(player);
                const speechConfig = sdk.SpeechConfig.fromSubscription(apk, reg);
                speechConfig.speechSynthesisVoiceName = voiceP;

                const synthesizer = new sdk.SpeechSynthesizer(speechConfig, audioConfig);
                synthesizer.synthesisCompleted = (sender, event) => {
                    import.meta.env.DEV === true && console.log("synth_cmplt");
                };

                synthesizerRef.current = synthesizer;
                setIsPlaying(true);

                //synthesizer.speakTextAsync(
                synthesizer.speakSsmlAsync(
                    prepSsml(text, voiceP, lcle),
                    result => {
                        if (result.reason === sdk.ResultReason.SynthesizingAudioCompleted) {
                            setIsLoadingAudio(false);
                            synthesizer.close();
                        }
                    },
                    error => {
                        console.error("errr_synth", error);
                        setIsPlaying(false);
                        synthesizer.close();
                    }
                );
            } catch (error: any) {
                console.error("Error Fetching or Playing Speech¦", error);
            }
        } else {
            console.log("No text to read on page", page);
            if (numPages && currentPage < numPages) {
                scrollToPageDefault(page + 1);
                fetchSpeechAudio(page + 1);
            }
        }
    };
    const playButStyles = {
        root: {
            borderRadius: "5px",
            color: "rgb(64, 84, 128);",
            margin: "5px 5px"
        }
    };
    const pauseButStyles = {
        root: {
            borderRadius: "5px",
            color: "rgb(64, 84, 128);",
            margin: "5px 5px"
        }
    };
    const closeButStyles = {
        root: {
            borderRadius: "5px",
            color: "orange"
        }
    };
    const callBackVisibleEntries = (visibleEntries: number[]) => {};

    const callBackIsLoading = (isLoading: boolean) => {
        import.meta.env.DEV === true && console.log("callBackIsLoading¦", currentPage, file);
    };
    const onContentShow = (fl: gfile | undefined, flIndex: number | undefined) => {
        setIsContentTableShowing(true);
    };
    const setActiveCitationStateFunc = (citI: string) => {
        if (citI != activeCitationState) {
            import.meta.env.DEV === true && console.log("***setActvCit_DIFFERENT¦SET***⇒", citI, "¦PREV¦", activeCitationState);
        }
        setActiveCitationState(citI);
    };
    const setActiveFileIdFunction = (flId: string) => {
        if (activeFileId !== flId) {
            import.meta.env.DEV === true && console.log("activeFileIdNO EQ flId¦WIPE BASE64", flId);
            //window.globalBase64String = "";
            //window.globalPDFPageDimensions = [];
        }
        setActiveFileId(flId);
    };
    return (
        <div className={styles.xplrrPDFMain}>
            <div className={styles.xplrrPDFMainInternalWrap}>
                <ThemeProvider theme={customTheme}>
                    <div className={styles.pdfMoHead}>
                        <div className={styles.headerFileDropdown}>
                            <FileDropdown
                                file={file}
                                fileListStateVar={fileListStateVar}
                                handleFileClick={handleFileClick}
                                onContentShow={onContentShow}
                                activeFileId={activeFileId}
                            />
                        </div>

                        {isContentTableShowing && (
                            <Callout
                                target={"#pdfTtldrpTrg"}
                                className={styles.fileListCallout}
                                onDismiss={() => setIsContentTableShowing(false)}
                                directionalHint={DirectionalHint.bottomLeftEdge}
                                isBeakVisible={false}
                            >
                                <div key={contentRefreshTriggerIncrement} className={styles.cntntf}>
                                    {renderLinkData()}
                                </div>
                            </Callout>
                        )}
                        {docAudio && (
                            <div className={styles.volButsContainer}>
                                <div className={styles.volButs}>
                                    {/* SPEAK BUTTON */}
                                    <IconButton
                                        onClick={() => (isPlaying ? stopSpeech() : playSpeech())}
                                        disabled={docRendered == true ? false : true}
                                        title="Speak Page"
                                        styles={isPlaying || isLoadingAudio ? pauseButStyles : playButStyles}
                                        className={selectedGroup.enabledocaudio == 1 ? styles.speakButShow : styles.speakButHide}
                                    >
                                        {isPlaying ? (
                                            <HiOutlineVolumeOff size={35} />
                                        ) : isLoadingAudio ? (
                                            <MdDownloading size={35} />
                                        ) : audioRef.current && audioPage == currentPage ? (
                                            <HiMiniPlayPause size={35} />
                                        ) : (
                                            <HiOutlineVolumeUp size={35} />
                                        )}
                                    </IconButton>
                                    {/* CLOSE BUTTON */}
                                    <IconButton onClick={onXplrrPDFDismiss} title="Close" styles={closeButStyles} className={styles.closeButIC}>
                                        <FaWindowClose size={35} />
                                    </IconButton>
                                </div>
                                <div className={styles.pageSelectorXplrr}>
                                    <div className={styles.pageSelectorOnly}>
                                        <PageSelector
                                            currentPage={currentPage}
                                            numPages={numPages ? numPages : 0}
                                            handlePageChange={handlePageChangeFromPageSelector}
                                        ></PageSelector>
                                    </div>
                                    <div className={styles.totPagesDisplay}>/ {numPages}</div>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className={styles.pdfWrapXplrr} id="pdfWrapXplrrIDENT">
                        {file?.invoicedata && file?.invoicedata != "" ? (
                            <div className={styles.invoiceDataPanel}>
                                {selectedGroup.invoicesgroup == 1 && selectedGroup.assistanttype != "dpa" ? (
                                    <JsonGrid
                                        jsonString={file?.invoicedata ?? "{excl:[],instr:[]}"}
                                        itmName={file?.name}
                                        fid={file?.fileid}
                                        loggedInUser={loggedInUser}
                                        onDataHighlight={onDataHighlight}
                                        onInvValueUpdate={onInvValueUpdate}
                                        group={selectedGroup}
                                    ></JsonGrid>
                                ) : (
                                    <JsonGridGen jsonString={file?.invoicedata ?? "{excl:[],instr:[]}"} />
                                )}
                            </div>
                        ) : null}
                        <div className={`${selectedGroup.invoicesgroup == 1 ? styles.pdfViewInv : styles.pdfViewC}`}>
                            <XplrrBOXPDF
                                file={file}
                                startPage={referencePage}
                                activeFileId={activeFileId ? activeFileId : ""}
                                handleTimeout={handleTimeout}
                                handleScroll={handleScroll}
                                setPages={setPages}
                                setContentList={setContentList}
                                callBackVisibleEntries={callBackVisibleEntries}
                                callBackIsLoading={callBackIsLoading}
                                srcLoc={selectedGroup.invoicesgroup == 1 ? "inv" : "modal"}
                                companyId={selectedGroup.companyid}
                            ></XplrrBOXPDF>
                        </div>
                    </div>
                </ThemeProvider>
            </div>
        </div>
    );
};

export default XplrrPDF;
