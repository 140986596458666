import React from "react";
import { useState, useContext, useEffect } from "react";
import { DetailsList, IColumn, DetailsListLayoutMode, DetailsHeader, IDetailsHeaderProps, DetailsRow, IDetailsRowProps } from "@fluentui/react/lib/DetailsList";
import styles from "./AnalyticsConvoList.module.css";
import { gChatlogItem } from "../../interfaces";
import { TextField, Dropdown, IDropdownOption, Modal, TooltipHost, DirectionalHint, ThemeProvider, PrimaryButton } from "@fluentui/react";
import ChartAnalyticsBar from "../ChartAnalyticsBar/ChartAnalyticsBar";
import { LuThumbsDown, LuThumbsUp } from "react-icons/lu";
import { GlobalContext } from "../../GlobalContext"; // Tests¦Context Accross Routes (ex. Chat/Search)
import { getHeaderG } from "../../api/qpi";
import { formatDateConvoList, formatDate30DaysConvoList } from "../../util_glob";
import { controlStylesAnalyticsConvoList, textFieldStylesAnalyticsConvoList, detailsListTheme } from "../../styles_glob";
import Papa from "papaparse";
import { PiFileCsv } from "react-icons/pi";

interface IUserListProps {
    keyA: string;
}
const onRenderDetailsHeader = (props: IDetailsHeaderProps, defaultRender?: (props: IDetailsHeaderProps) => JSX.Element) => {
    const customStyles = {
        root: {
            padding: "0"
        }
    };
    return <DetailsHeader {...props} styles={customStyles} />;
};

const AnalyticsConvoList: React.FC<IUserListProps> = ({ keyA }) => {
    const { loggedInUser, selectedGroup } = useContext(GlobalContext);
    const [startDate, setStartDate] = useState(formatDate30DaysConvoList(new Date().toISOString()));
    const [endDate, setEndDate] = useState(formatDateConvoList(new Date().toISOString()));
    const [userMail, setUserMail] = useState<string | number | undefined>("");

    const [isItemDetailsOpen, setIsItemDetailsOpen] = useState(false);
    const [chosenItem, setChosenItem] = useState<gChatlogItem>();
    const [spaceChats, setSpaceChats] = useState<gChatlogItem[]>([]);
    const [fltrdChats, setFltrdChats] = useState<gChatlogItem[]>([]);
    const [spaceChatsLoaded, setSpaceChatsLoaded] = useState(false);

    useEffect(() => {
        qGetChatLog().then(setSpaceChats);
    }, []);

    useEffect(() => {
        setFltrdChats(
            spaceChats.filter(ch => {
                const date = new Date(ch.key.toString().replace(/^(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})$/, "$1-$2-$3T$4:$5:$6Z"));
                return date >= new Date(startDate) && date <= new Date(endDate) && (!userMail || ch.usermail === userMail);
            })
        );
    }, [spaceChats, endDate, startDate, userMail]);

    const columns: IColumn[] = [
        { key: "column1", name: "Date", fieldName: "key", minWidth: 100, maxWidth: 100, isResizable: true },
        {
            key: "column11",
            name: "",
            onRender: (item: gChatlogItem) => {
                {
                    return item.positive == 1 ? (
                        <LuThumbsUp color="#4bbc8e" size="17" />
                    ) : item.positive == 0 ? (
                        <TooltipHost content={item.feedback} directionalHint={DirectionalHint.topCenter}>
                            <LuThumbsDown color="#ffb279" size="17" />
                        </TooltipHost>
                    ) : (
                        ""
                    );
                }
            },
            fieldName: "positive",
            minWidth: 20,
            maxWidth: 20,
            isResizable: false
        },
        {
            key: "column22",
            name: "Feedback",
            onRender: (item: gChatlogItem) => {
                return item.feedback;
            },
            fieldName: "comment",
            minWidth: 50,
            maxWidth: 100,
            isResizable: true
        },
        { key: "column2", name: "User", fieldName: "usermail", minWidth: 50, maxWidth: 100, isResizable: true },
        { key: "column3", name: "Query", fieldName: "question", minWidth: 50, maxWidth: 300, isResizable: true },
        { key: "column4", name: "Response", fieldName: "response", minWidth: 50, maxWidth: 400, isResizable: true }
    ];
    const dropdownOptions: IDropdownOption[] = [
        { key: "", text: "All" },
        ...spaceChats
            .reduce<string[]>((acc, item) => {
                if (!acc.includes(item.usermail)) {
                    acc.push(item.usermail);
                }
                return acc;
            }, [])
            .map(mail => ({ key: mail, text: mail }))
    ];
    const qGetChatLog = async () => {
        const params = new URLSearchParams({
            groupid: selectedGroup.selectionId
        });
        const response = await fetch("/qGetChatLog" + `?${params.toString()}`, {
            headers: getHeaderG(loggedInUser)
        });
        if (!response.ok) throw Error("qGetChatLog¦Failed to Get Log");

        let spaceChatLog: gChatlogItem[] = await response.json();
        setSpaceChats(spaceChatLog);
        setSpaceChatsLoaded(true);
        return spaceChatLog;
    };
    const formatText = (content: string) => {
        const output = [];
        const lines = content.split("\\n");

        let listItems: any = [];
        lines.forEach((line, index) => {
            // Handle headers
            if (line.startsWith("### ")) {
                // If there are accumulated list items, add them as a list before resetting
                if (listItems.length > 0) {
                    output.push(<ul key={`ul-${index}`}>{listItems}</ul>);
                    listItems = [];
                }
                output.push(<h3 key={index}>{line.substring(4)}</h3>);
            }
            // Handle list items
            else if (line.match(/^\d+\. /)) {
                listItems.push(<li key={index}>{line.substring(line.indexOf(" ") + 1)}</li>);
            }
            // Handle bold text within lines
            else if (line.includes("**")) {
                const parts = line.split("**").map((part, i) => (i % 2 === 1 ? <strong key={i}>{part}</strong> : part));
                output.push(<p key={index}>{parts}</p>);
            }
            // Add explicit line breaks for empty lines
            else if (line.trim() === "") {
                output.push(<br key={index} />);
            }
            // Normal paragraph text
            else {
                output.push(<p key={index}>{line}</p>);
            }
        });

        // If there are remaining list items at the end, add them as a list
        if (listItems.length > 0) {
            output.push(<ul key="final-ul">{listItems}</ul>);
        }

        return output;
    };

    const downloadCSV = () => {
        const csv = Papa.unparse(
            fltrdChats.map(item => ({
                ...item,
                key: `${item.key.toString().slice(0, 4)}-${item.key.toString().slice(4, 6)}-${item.key.toString().slice(6, 8)} ${item.key
                    .toString()
                    .slice(8, 10)}:${item.key.toString().slice(10, 12)}:${item.key.toString().slice(12, 14)}`
            })),
            {
                quotes: true, // Wrap all fields in quotes for safety
                header: true // Include header row automatically
            }
        );
        // Add UTF-8 BOM for correct Cyrillic encoding
        const csvWithBom = "\uFEFF" + csv;
        const blob = new Blob([csvWithBom], { type: "text/csv;charset=utf-8" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `kgnf_analytics_chatlog.csv`;
        a.click();
        window.URL.revokeObjectURL(url);
    };

    return (
        <div className={styles.container}>
            {spaceChatsLoaded == true ? (
                <div className={styles.timeFilterInputsContainer}>
                    <div className={styles.timeFilterInputsContainer}>
                        <TextField
                            styles={textFieldStylesAnalyticsConvoList}
                            label="Start Date"
                            type="date"
                            value={startDate}
                            onChange={(_, date) => setStartDate(date || "")}
                            className={styles.fCo}
                        />
                        <TextField
                            styles={textFieldStylesAnalyticsConvoList}
                            label="End Date"
                            type="date"
                            value={endDate}
                            onChange={(_, date) => setEndDate(date || "")}
                            className={styles.fCo}
                        />
                        <Dropdown
                            label="User"
                            selectedKey={userMail}
                            onChange={(_, item) => setUserMail(item?.key)}
                            options={dropdownOptions}
                            className={styles.fCo}
                        />
                        {fltrdChats.length > 0 && (
                            <div className={styles.downloadChatLogCsvIcon} onClick={downloadCSV}>
                                <PiFileCsv size="25" />
                            </div>
                        )}
                    </div>
                    {fltrdChats.length > 0 ? (
                        <div className={styles.chartBar}>
                            <ChartAnalyticsBar data={fltrdChats.map(item => ({ ...item, key: item.key.toString().substring(0, 8) }))} />
                        </div>
                    ) : (
                        <div className={styles.noDataMessage}>No data available for selected filters</div>
                    )}
                    {fltrdChats.length > 0 && (
                        <ThemeProvider theme={detailsListTheme}>
                            <div style={{ height: "calc(100vh - 500px)", overflow: "auto" }}>
                                <DetailsList
                                    className={styles.detailsList}
                                    styles={controlStylesAnalyticsConvoList}
                                    items={fltrdChats.map(item => ({
                                        ...item,
                                        key: `${item.key.toString().slice(0, 4)}-${item.key.toString().slice(4, 6)}-${item.key
                                            .toString()
                                            .slice(6, 8)} ${item.key.toString().slice(8, 10)}:${item.key.toString().slice(10, 12)}:${item.key
                                            .toString()
                                            .slice(12, 14)}`
                                    }))}
                                    columns={columns}
                                    setKey="set"
                                    layoutMode={DetailsListLayoutMode.justified}
                                    selectionPreservedOnEmptyClick={true}
                                    ariaLabelForSelectionColumn="Toggle selection"
                                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                                    selectionMode={0}
                                    onRenderDetailsHeader={(props?: IDetailsHeaderProps) => onRenderDetailsHeader(props!)}
                                    onItemInvoked={item => {
                                        setChosenItem(item);
                                        setIsItemDetailsOpen(true);
                                    }}
                                    onRenderRow={(prps: IDetailsRowProps | undefined): JSX.Element => {
                                        return <DetailsRow {...(prps as IDetailsRowProps)} styles={prps?.styles} />;
                                    }}
                                    onShouldVirtualize={() => fltrdChats.length > 10}
                                />
                            </div>
                        </ThemeProvider>
                    )}

                    <Modal
                        isOpen={isItemDetailsOpen}
                        onDismiss={() => {
                            setIsItemDetailsOpen(false);
                        }}
                        isBlocking={false}
                        containerClassName={styles.spaceAnalyticsModal}
                    >
                        <div className={styles.modalContent}>
                            <div className={styles.modalHeader}>
                                <h2>Chat Details</h2>
                            </div>
                            <div className={styles.modalBody}>
                                <table className={styles.detailsTable}>
                                    <tbody>
                                        <tr>
                                            <th>Date</th>
                                            <td>{chosenItem?.key}</td>
                                        </tr>
                                        <tr>
                                            <th>User</th>
                                            <td>{chosenItem?.usermail}</td>
                                        </tr>
                                        <tr>
                                            <th>Query</th>
                                            <td>{chosenItem?.question}</td>
                                        </tr>
                                        <tr>
                                            <th>Response</th>
                                            <td>
                                                <div className={styles.detailsResponse}>
                                                    {formatText(chosenItem ? chosenItem?.response.replace(/"/g, "") : "")}
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className={styles.modalFooter}>
                                <button
                                    className={styles.closeButton}
                                    onClick={() => {
                                        setIsItemDetailsOpen(false);
                                    }}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </Modal>
                </div>
            ) : (
                <div className={styles.loadingIndicatorCenterScreen}>
                    <div className={styles.typingDots}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AnalyticsConvoList;
