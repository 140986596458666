import { Outlet } from "react-router-dom";
import { useEffect, useState } from "react";
import styles from "./Layout.module.css";
import { authMS, authLOCAL, authGOOGLE } from "../../authConfig";
import { useMsal } from "./../../MsalContext";
import { LoginButton } from "../../components/LoginButton";
import { AccountInfo, AuthenticationResult } from "@azure/msal-browser";
import kognifyBanner from "../../assets/kognifyBanner.png";
import kgnfyBanner from "../../assets/kgnfyBanner.png";
import kognifyLogo from "../../assets/kognify_logo.svg";

import loginLoc from "../../assets/Font_Awesome_5_Solid_Envelope.svg";
import LoginMS from "../../assets/Microsoft.svg";
import loginGoo from "../../assets/google.svg";

import { TooltipHost, DirectionalHint } from "@fluentui/react";

const Layout = () => {
    const { msalInstance, updateAuthority } = useMsal();

    const [msalReady, setMsalReady] = useState<boolean>(false);
    const [logPageReady, setLogPageReady] = useState<boolean>(false);
    const [isIFrame, setIsIFrame] = useState<boolean>(false);

    const getRedeemToken = () => {
        let params = new URLSearchParams(window.location.search);
        let redeemCode = params.get("redeemCode");
        return redeemCode;
    };
    const getRedeemSpaceName = () => {
        let params = new URLSearchParams(window.location.search);
        let redeemSpaceName = params.get("spt");
        return redeemSpaceName;
    };
    const getTokenMail = (token: any) => {
        import.meta.env.DEV === true && console.log("token¦", token);
        return token.idTokenClaims?.emails?.length > 0 ? token.idTokenClaims?.emails[0] : token.idTokenClaims?.email;
    };
    const getAnonToken = () => {
        let params = new URLSearchParams(window.location.search);
        let aT = params.get("A");
        return aT;
    };
    const getUrlUid = () => {
        let params = new URLSearchParams(window.location.search);
        return params.get("uid") || "";
    };
    const navToLogin = () => {
        if (getAnonToken() == "1") {
            fetch(import.meta.env.VITE_EXT_SERV_ANIP)
                .then(response => response.json())
                .then(data => {
                    let ipStr: string;
                    let urluid = getUrlUid();
                    let rndStr = getUrlUid() == "" ? generateRandomString(10) : urluid;
                    if (data && data.ip && data.ip != "") {
                        ipStr = data.ip;
                    } else {
                        ipStr = rndStr;
                    }
                    fetch("/qAddQuest", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            qUsr: ipStr,
                            rndStr: rndStr,
                            hsh: window.location.hash
                        })
                    })
                        .then(response => response.json())
                        .then(rspns => {
                            import.meta.env.DEV === true && console.log("LT¦ADD_QUEST", ipStr);
                            localStorage.setItem("msalAuth4", authLOCAL.authority);
                            localStorage.setItem("msalAuth4Tok", JSON.stringify(rspns));

                            setMsalReady(true);
                        });
                })
                .catch(error => {
                    let ipStr = generateRandomString(10);
                    let rndStr = ipStr;
                    fetch("/qAddQuest", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            qUsr: ipStr,
                            rndStr: rndStr
                        })
                    })
                        .then(response => response.json())
                        .then(rspns => {
                            import.meta.env.DEV === true && console.log("LT¦ADD_QUEST", ipStr);
                            localStorage.setItem("msalAuth4", authLOCAL.authority);
                            localStorage.setItem("msalAuth4Tok", JSON.stringify(rspns));

                            setMsalReady(true);
                        });
                });
        } else {
            setLogPageReady(true);
        }
    };
    function generateRandomString(length = 10) {
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let result = "";
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    }
    const onAction = (resp: any) => {
        // Callback from Auth Buttons
        import.meta.env.DEV === true && console.log("LYT¦LOGIN_BUTTON¦onAction", resp);

        let mailChk = getTokenMail(resp);
        if (mailChk !== undefined && mailChk !== null && mailChk !== "") {
            import.meta.env.DEV === true && console.log("LYT¦LOGIN_BUTTON¦onAction⇛setMsalReady", mailChk);
            localStorage.setItem("msalAuth4Tok", JSON.stringify(resp));
            setMsalReady(true);
        } else {
            if (msalInstance !== null) {
                const acc: AccountInfo | null = msalInstance.getActiveAccount();

                if (acc !== null) {
                    msalInstance?.setActiveAccount(acc);
                    import.meta.env.DEV === true && console.log("LYT¦onAction¦setActiveAccount⇛", acc);
                    setMsalReady(true);
                } else {
                    navToLogin();
                    //setLogPageReady(true);
                }
            } else {
                import.meta.env.DEV === true && console.log("LYT¦onAction¦NO MSAL INSTANCE");
                navToLogin();
                //setLogPageReady(true);
            }
        }
    };

    const checkIFrame = (params: any) => {
        let isfrm = params.get("isfrm");
        if (isfrm !== null) {
            setIsIFrame(true);
            localStorage.setItem("isFrmI", "True");
        } else {
            setIsIFrame(false);
            localStorage.removeItem("isFrmI");
        }
    };
    const getFragmentParams = () => {
        const params = new URLSearchParams(window.location.hash.slice(1));
        params.forEach((value, key) => {
            console.log(`PRM¦ ${key}: ${value}`);
        });
    };

    useEffect(() => {
        import.meta.env.DEV === true && console.log("kgnf_msal", localStorage.getItem("msalAuth4"));
        let params = new URLSearchParams(window.location.search);

        const utok = localStorage.getItem("msalAuth4Tok");
        //getFragmentParams();
        if (utok) {
            const utokcache: AuthenticationResult = JSON.parse(utok);
            const mailChk = getTokenMail(utokcache);
            if (mailChk !== undefined && mailChk !== null && mailChk !== "") {
                setMsalReady(true);
                return;
            }
        }

        if (msalInstance === null) {
            import.meta.env.DEV === true && console.log("LYT¦UI⇒msalInstance===NULL");
            return;
        }
        checkIFrame(params);
        const accs: AccountInfo[] | null = msalInstance.getAllAccounts();
        if (accs.length > 0) {
            import.meta.env.DEV === true && console.log("accs_length", accs?.length, accs);
            const exp = accs[0]?.idTokenClaims?.exp;

            const mailChk = getTokenMail(accs[0]);
            if (mailChk !== undefined && mailChk !== null && mailChk !== "") {
                if (accs[0].homeAccountId) {
                    let homeAccId = accs[0].homeAccountId;
                    if (homeAccId.indexOf("google_signup_signin") > -1) {
                        import.meta.env.DEV === true && console.log("LYT¦SET_TOKEN_FROM_ACCOUNT_CACHE¦GOOGLE", homeAccId, accs?.length, accs);

                        localStorage.setItem("msalAuth4", authGOOGLE.authority);
                        localStorage.setItem("msalAuth4Tok", JSON.stringify(accs[0]));

                        setMsalReady(true);

                        return;
                    } else if (homeAccId.indexOf("idp_aad_multi_single") > -1) {
                        // MS
                        import.meta.env.DEV === true && console.log("LYT¦SET_TOKEN_FROM_ACCOUNT_CACHE¦MS", homeAccId, accs?.length, accs);

                        localStorage.setItem("msalAuth4", authMS.authority);
                        localStorage.setItem("msalAuth4Tok", JSON.stringify(accs[0]));

                        setMsalReady(true);

                        return;
                    } else if (homeAccId.indexOf("b2c_1_signupsignin") > -1) {
                        // LOCAL
                        import.meta.env.DEV === true && console.log("LYT¦SET_TOKEN_FROM_ACCOUNT_CACHE¦LOCAL", homeAccId, accs?.length, accs);

                        localStorage.setItem("msalAuth4", authLOCAL.authority);
                        localStorage.setItem("msalAuth4Tok", JSON.stringify(accs[0]));

                        setMsalReady(true);

                        return;
                    }
                }
            }

            import.meta.env.DEV === true && console.log("no_account_01");

            let passRes = params.get("passreset");
            if (passRes !== null) {
                localStorage.removeItem("msal.token");
                sessionStorage.removeItem("msal.token");
                updateAuthority(authLOCAL.authority);
            }
            navToLogin();
            //setLogPageReady(true);
        } else {
            import.meta.env.DEV === true && console.log("no_account_02");

            let passRes = params.get("passreset");
            if (passRes !== null) {
                localStorage.removeItem("msal.token");
                sessionStorage.removeItem("msal.token");
                updateAuthority(authLOCAL.authority);
            }
            navToLogin();
            //setLogPageReady(true);
        }
    }, [msalInstance]);

    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}></header>
            {msalReady === true ? (
                <Outlet />
            ) : logPageReady == true ? (
                <div className={styles.area}>
                    <div className={styles.loginContainer}>
                        {window.location.hostname !== "app.kognify.ai" && window.location.hostname !== "kognify.ai" ? (
                            <img src={kognifyLogo} height="100px" className={styles.kognifyBanner}></img>
                        ) : (
                            <img src={kognifyBanner} height="50px" className={styles.kognifyBanner}></img>
                        )}
                        {getRedeemToken() !== null ? (
                            <div className={styles.redeemToken}>
                                <div className={styles.redeemGreeting}>
                                    Hey, you were invited to a Kognify Space <br />
                                    <span style={{ fontSize: "25px", lineHeight: "2" }}>
                                        <b>{getRedeemSpaceName()}</b>
                                    </span>
                                    <br /> Please signup in your preffered way
                                </div>
                            </div>
                        ) : null}
                        <TooltipHost
                            content="Sign Up/Sign In with an Email Address"
                            directionalHint={import.meta.env.VITE_LOCAL_ONLY_LOGIN_MODE != "True" ? DirectionalHint.topCenter : DirectionalHint.bottomCenter}
                        >
                            <LoginButton
                                imgSrc={loginLoc}
                                text="SIGN IN WITH MAIL"
                                authorityURI={authLOCAL.authority}
                                onAction={onAction}
                                isIFrame={isIFrame}
                            />
                        </TooltipHost>
                        {import.meta.env.VITE_LOCAL_ONLY_LOGIN_MODE != "True" && (
                            <div>
                                <TooltipHost content="Sign In with your Work or Personal Microsoft Account" directionalHint={DirectionalHint.rightCenter}>
                                    <LoginButton
                                        imgSrc={LoginMS}
                                        text="SIGN IN WITH MICROSOFT"
                                        authorityURI={authMS.authority}
                                        onAction={onAction}
                                        isIFrame={isIFrame}
                                    />
                                </TooltipHost>
                                <TooltipHost content="Sign In with your Personal or Worplace Google Account" directionalHint={DirectionalHint.bottomCenter}>
                                    <LoginButton
                                        imgSrc={loginGoo}
                                        text="SIGN IN WITH GOOGLE"
                                        authorityURI={authGOOGLE.authority}
                                        onAction={onAction}
                                        isIFrame={isIFrame}
                                    />
                                </TooltipHost>
                            </div>
                        )}
                    </div>
                    <ul className={styles.circles}>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                </div>
            ) : (
                <div className={styles.area}>
                    <ul className={styles.circles}>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                </div>
            )}
        </div>
    );
};

export default Layout;
